// Шрифты
$ff-regular: 'optimaregular', sans-serif;

$white: #fff;
$black: #000;
$red: #f00;
$tr: transparent;


$bg_main: #000;
$text_main: #fff;

$black_16: #161718;
$grey_65: #657974;
$grey_26: #262626;
$grey_87: #878787;
$grey_a1: #a1a1a1;
$grey_c: #ccc;
$grey_53: #535353;
$brown: #ae8057;


$bg_addit: #000;
$color_addit: #fff;
$nav_addit: #262626;
