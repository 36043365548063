.footer {
    background-color: $grey_26;
    background-image: repeating-linear-gradient(110deg,#2f2f2f,#2f2f2f 1px,transparent 1px,transparent 6px,#2f2f2f 6px,#2f2f2f 7px,transparent 7px,transparent 12px,#2f2f2f 12px,#2f2f2f 13px,transparent 13px,transparent 18px,#2f2f2f 18px,#2f2f2f 19px,transparent 19px,transparent 24px,#2f2f2f 24px,#2f2f2f 25px,transparent 25px,transparent 30px,#2f2f2f 30px,#2f2f2f 31px,transparent 31px,transparent 45px),repeating-linear-gradient(-110deg,#2f2f2f,#2f2f2f 1px,transparent 1px,transparent 6px,#2f2f2f 6px,#2f2f2f 7px,transparent 7px,transparent 12px,#2f2f2f 12px,#2f2f2f 13px,transparent 13px,transparent 18px,#2f2f2f 18px,#2f2f2f 19px,transparent 19px,transparent 24px,#2f2f2f 24px,#2f2f2f 25px,transparent 25px,transparent 30px,#2f2f2f 30px,#2f2f2f 31px,transparent 31px,transparent 45px);
    padding: 40px 0;
    &__container {
      width: 100%;
      max-width: 100%;
      padding: 0 calc(99.9% / 12 - (20px - 20px / 12));
    }
    .header_lang {
        display: flex;
        align-items: center;
        justify-content: center;
        &__li +  &__li {
            position: relative;
            &::after {
                @include psevdo;
                top: 0;
                bottom: 0;
                left: 0;
                border-left: 1px solid $white;
            }
        }
        &__link {
            font-size: 12px;
            display: block;
            padding: 12px 5px 8px;
            text-transform: uppercase;
            color: $white;
            opacity: 0.7;
            position: relative;
            &.active_link {
                font-family: $ff-regular;
                opacity: 1;
            }
        }
    }
    &__copyright {
        text-align: center;
        font-size: 12px;
        color: rgba($text_main, .5);
        text-transform: uppercase;
        letter-spacing: 1px;
    }
    &__top {
        border-bottom: 1px solid rgba($text_main, .5);
        padding-bottom: 25px;
        margin-bottom: 25px;
        position: relative;
    }
    &__menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 -5px;
        &_item {
            display: flex;
            align-items: center;
            padding: 0 5px;
            width: 42%;
        }
        &_center {
            justify-content: center;
            width: 16%;
        }
        &_left {
            justify-content: flex-start;
            margin: 0 -5px;
            .footer__link {
                text-align: left;
            }
        }
        &_right {
            justify-content: flex-end;
            margin: 0 -5px;
            .footer__li {
                text-align: right;
            }
            .footer__link {
                text-align: right;
            }
        }
    }
    &__li {
        padding: 0 5px;
        // min-width: 33.3%;
        // flex: 1 0 auto;
    }
    &__link {
        width: 100%;
        color: rgba($text_main, .5);
        position: relative;
        display: inline-block;
        padding: 12px 10px 8px;
        font-size: 12px;
        letter-spacing: 1px;
        text-transform: uppercase;
        transition: color .3s cubic-bezier(.445,.05,.55,.95);
        &:hover,
        &:focus,
        &:active {
            color:  rgba($text_main, 1);
        }
    }
    &__logo {
        width: 148px;
        height: 64px;
        position: relative;
        overflow: hidden;
        &_inner {
            display: block;
            height: 0;
            padding: 0 0 100%;
            overflow: hidden;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 100%;
            svg {
                fill: $text_main;
                top: 0;
                left: 0;
                position: absolute;
                width: 100%;
                height: 100%;
            }
        }
    }
}

@media (min-width: 1900px) {
  .footer {
     &__top {
       padding-left: 50px;
       padding-right: 50px;
     }
     &__li {
       padding: 0 20px;
     }
     &__menu {
       &_left,
       &_right {
         margin: 0 -20px;
       }
     }
  }
}

@media (max-width: 1439px) {
  .footer {
    &__menu {
      justify-content: center;
      flex-wrap: wrap;
      &_item {
        width: auto;
      }
      &_center {
        width: 100%;
        order: -1;
        display: flex;
        margin-bottom: 20px;
      }
    }
  }
}

@media (max-width: 1023px) {
  .footer {
    &__container {
      padding: 0 calc(99.9% / 12 - (10px - 10px / 12));
    }
    &__menu {
      flex-direction: column;
      align-items: center;
      &_item {
        display: block;
        width: 100%;
      }
      &_center {
        order: -1;
        display: flex;
        margin-bottom: 20px;
      }
      &_left,
      &_right {
        .footer {
          &__link {
            text-align: center;
          }
        }
      }
    }
  }
}
