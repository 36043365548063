.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  transition: all .25s linear .15s;
  background: $tr;
  &.header__page {
    position: absolute;
  }
  &__cover {
    width: 100%;
    margin: 0 auto;
  }
  &__container {
    position: relative;
    display: flex;
    height: 65px;
    padding: 0 40px;
    width: 100%;
    background: $tr;
    color: $text_main;
    align-items: center;
  }
  &__logo {
    position: absolute;
    width: 169px;
    height: 18px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    &_inner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 169px;
    }
    svg {
      fill: $white;
    }
  }
  &__item {
    width: 50%;
    &--adaptive {
      display: none;
    }
  }
  &__left {
      text-align: left;
  }
  &__right {
      text-align: right;
      .header__icon svg {
        transform: rotate(180deg);
      }
  }
  &__icon {
    display: inline-block;
    width: 70px;
    height: 16px;
    cursor: pointer;
    opacity: 1;
    transition: opacity .4s linear;
  }
}

.menu {
  display: flex;
  height: 100%;
  &__cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 40px;
  }
  &__item {
    width: 50%;
    display: flex;
  }
  &__left {
    justify-content: flex-start;
  }
  &__right {
    justify-content: flex-end;
  }
  &__ul {
    display: inline-flex;
    align-items: center;
    margin: 0 -8px;
    transition: .4s cubic-bezier(.7,0,1,.3),opacity .4s linear,color;
    opacity: 0;
    &--left {
      justify-content: flex-start;
      transform: translateX(-60px);
    }
    &--right {
      justify-content: flex-end;
      transform: translateX(60px);
    }
  }
  &__li {
    padding: 0 8px;
    &--adaptive {
      display: none;
    }
  }
  &__link {
    text-transform: uppercase;
    white-space: nowrap;
    pointer-events: all;
    font-size: 15px;
    position: relative;
    display: inline-block;
    padding: 10px 4px;
    text-decoration: none;
    color: $text_main;
    letter-spacing: .06em;
    cursor: pointer;
    transition: color .2s,opacity .3s;
    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 24px;
      height: 24px;
      top: 50%;
      left: calc(50% - 12px);
      opacity: 0;
      background-repeat: no-repeat;
      background-size: contain;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      transition: opacity .3s;
      background-image: url(../images/cancel.svg);
      background-repeat: no-repeat;
      background-color: inherit;
    }
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      left: -1px;
      margin-top: 2px;
      opacity: 0;
      transition: opacity .2s .2s;
      border-bottom: 2px solid rgba($text_main,.4);
    }
    &:hover,
    &:focus,
    &:active {
      &:after {
        opacity: 1;
      }
    }
    &.js_active {
      color: rgba($text_main, .2);
      svg {
        opacity: .2;
      }
      &:before {
        opacity: 1;
        transition: opacity .2s .2s;
      }
    }
  }
  &__search {
    svg {
      width: 18px;
      height: 17px;
    }
  }
}

.menu_li--adaptive {
  display: none;
}
.menu__search_text {
  display: none;
}

.submenu__li--back {
  display: none;
}

.header {
  &:hover,
  &:focus,
  &:active,
  &.js_active {
    .header {
      &__icon {
        opacity: 0;
        transition: opacity .3s linear .2s;
      }
    }
    .menu {
      &__ul {
        opacity: 1;
        transform: translateX(0);
        transition: transform .5s cubic-bezier(0,.7,.3,1),opacity .3s linear .2s,-webkit-transform .5s cubic-bezier(0,.7,.3,1);
      }
    }
  }
  &.js_active_left {
    background: $black_16;
    transition: all .25s linear .15s;
    .menu__ul--left{
      opacity: 1;
      transform: translateX(0);
      transition: transform .5s cubic-bezier(0,.7,.3,1),opacity .3s linear .2s,-webkit-transform .5s cubic-bezier(0,.7,.3,1);
    }
    .header__icon--left {
      opacity: 0;
      transition: opacity .3s linear .2s;
    }
  }
  &.js_active_right {
    background: $black_16;
    transition: all .25s linear .15s;
    .menu__ul--right {
      opacity: 1;
      transform: translateX(0);
      transition: transform .5s cubic-bezier(0,.7,.3,1),opacity .3s linear .2s,-webkit-transform .5s cubic-bezier(0,.7,.3,1);
    }
    .header__icon--right {
      opacity: 0;
      transition: opacity .3s linear .2s;
    }
  }
}


.submenu {
  &__search {
      max-width: 1655px;
      padding-top: 80px;
      margin: 0 auto;
  }
  &__cover {
    position: fixed;
    z-index: -1;
    top: 65px;
    left: -40px;
    width: 101vw;
    height: 100vh;
    overflow-y: auto;
    padding: 55px 60px 80px;
    background: $black_16;
    color: $text_main;
    transition: transform .5s cubic-bezier(.8,0,.2,1),opacity .3s linear,-webkit-transform .5s cubic-bezier(.8,0,.2,1);
    opacity: 0;
    pointer-events: none;
    display: none;
    &--right {
      left: auto;
      right: -40px;
    }
    &.js_active {
      transition: transform .5s cubic-bezier(0,.7,.3,1),opacity .25s linear .15s,-webkit-transform .5s cubic-bezier(0,.7,.3,1);
      opacity: 1;
      pointer-events: all;
      display: block;
    }
  }
  &__ul {
    padding-left: 45px;
    width: 100%;
    position: relative;
  }
  &__li + &__li{
    margin-top: 4.5vh;
  }
  &__link {
    font-size: 5vh;
    display: inline-block;
    line-height: 1em;
    text-transform: uppercase;
    color: $text_main;
    opacity: 0.6;
    transition: opacity .25s linear;
    &:hover,
    &:focus,
    &:active,
    &.js_active {
      opacity: 1;
    }
    &--3 {
      font-size: 3vh;
    }
  }
  &__progress {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 140px;
    transition: opacity .25s linear;
    &.js_active {
        opacity: 1;
    }
  }
}

.progress {
  position: relative;
  top: -5px;
  &__item + &__item{
      margin-top: 20px;
  }
  &__item {
    display: flex;
  }
  &__name {
    font-size: 11px;
    text-transform: uppercase;
    line-height: 2.18em;
    letter-spacing: .083em;
    color: $text_main;
  }
  &__progress {
    display: block;
    width: 194px;
    height: 2px;
    color: $text_main;
    border: none;
    border-radius: 4px;
    background-color: rgba($text_main, .2);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  &__value {
    position: relative;
    display: flex;
    flex-direction: row;
    align-self: center;
    margin-left: 18px;
    font-size: 24px;
    min-width: 110px;
  }
  &__type {
    margin-left: 7px;
    opacity: .5;
  }
  &__notvalue {
    font-size: 12px;
    line-height: 32px;
    letter-spacing: .15em;
  }
}

progress[value]::-webkit-progress-value,
progress[value]::-moz-progress-value {
  background: $text_main;
}

progress {
  color: $text_main;
}

progress::-webkit-progress-value {
  background: $text_main;
}

progress::-moz-progress-bar {
  background: $text_main;
}

.search {
  &__form {
    position: relative;
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $text_main;
      text-transform: uppercase;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: $text_main;
      text-transform: uppercase;
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: $text_main;
      text-transform: uppercase;
    }
    :-moz-placeholder { /* Firefox 18- */
      color: $text_main;
      text-transform: uppercase;
    }
  }
  &__input {
    display: block;
    width: 100%;
    height: auto;
    padding: .5em 50px .22em 0;
    margin-bottom: .28em;
    margin-left: 0;
    font-size: 46px;
    text-transform: none;
    color: $text_main;
    border: none;
    border-radius: none;
    background: 0 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-bottom: 2px solid $text_main;
    @extend %transition;
    &:hover,
    &:focus,
    &:active {
      border-bottom: 2px solid $text_main;
    }
  }
  &__btn {
    position: absolute;
    display: block;
    width: 34px;
    height: 36px;
    bottom: 20px;
    right: 0;
    background: $tr;
    padding: 0;
    svg {
      fill: $text_main;
    }
  }
}


@media (max-width: 1199px) {
  .header__item {
    display: none;
    &--adaptive {
      display: block;
      margin-left: auto;
      position: relative;
      z-index: 5;
      .header__icon {
        opacity: 1;
        height: 30px;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          display: block;
          width: 17px;
          height: 17px;
          top: 50%;
          right: 0;
          opacity: 0;
          background-repeat: no-repeat;
          background-size: contain;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          transition: opacity .3s;
          background-image: url(../images/cancel.svg);
          background-repeat: no-repeat;
          background-color: inherit;
        }
        &.js_active {
          svg {
            opacity: 0;
          }
          &:before {
            opacity: 1;
          }

        }
      }
    }
  }
  .header {
    &:hover,
    &:focus,
    &:active,
    &.js_active {
      .header {
        &__icon {
          opacity: 1;
          transition: opacity .3s linear .2s;
        }
      }
    }
    &.js_active_right,
    &.js_active_left {
      background: $tr;
    }
  }
  .menu {
    display: block;
    height: 100%;
    padding: 0 20px;
    overflow-y: auto;
    &__cover {
      position: fixed;
      background: $black_16;
      padding: 75px 0 50px;
      opacity: 0;
      pointer-events: none;
      display: none;
      &.js_active {
        pointer-events: all;
        opacity: 1;
        display: block;
      }
    }
    &__right {
      justify-content: flex-start;
    }
    &__item {
      width: 100%;
    }
    &__ul {
      flex-direction: column;
      align-content: flex-start;
      opacity: 1;
      transform: translateX(0);
      width: 100%;
      &--left,
      &--right {
        transform: translateX(0);
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
    &__link {
      font-size: 30px;
      letter-spacing: .4px;
      text-transform: none;
      padding: 15px 0;
      white-space: normal;
      &.js_active {
        color: $text_main;
        svg {
          opacity: 1;
        }
        &:before {
          opacity: 0;
          transition: opacity .2s .2s;
        }
      }
      &.js_hidden {
        font-size: 0;
        padding: 0;
        height: 0;
      }
    }
    &:hover,
    &:active,
    &:focus {
      &:after {
        opacity: 0;
      }
    }
    &__li {
      &--search {
        width: 100%;
      }
      &--adaptive {
        display: block;
      }
    }
    &__search {
      width: 100%;
      display: flex;
      align-items: baseline;
      border-bottom: 1px solid rgba($text_main,.1);
      justify-content: space-between;
      padding-bottom: 5px;
    }
  }
  .menu__search_text {
    display: inline-block;
  }
  .submenu {
    &__cover {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 20px 0 0;
      &.js_active {
        display: block;
        z-index: 1;
      }
    }
    &__ul {
      padding-left: 0;
    }
    &__link {
      opacity: 1;
    }
    &__progress {
      display: none;
    }
  }
  .menu_li--adaptive {
    display: block;
  }
  .menu__item.menu__left {
    display: none;
  }
  .menu__item.menu__right {
    height: 100%;
  }
  .submenu__li--back {
    display: inline-flex;
    align-items: center;
    margin-bottom: 50px;
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;
    .back_text {
      padding-left: 10px;
    }
    svg {
        width: 20px;
        height: 20px;
    }
  }
  .search__input {
    font-size: 30px;
  }
  .search__btn {
    width: 25px;
    height: 25px;
    bottom: 10px;
  }
}

@media (max-width: 767px) {
  .header {
    &__container {
      padding: 0 15px;
    }
  }
}
