@font-face {
    font-family: 'optimaregular';
    src: url('../fonts/optima.eot');
    src: url('../fonts/optima.eot?#iefix') format('embedded-opentype'),      
         url('../fonts/optima.woff') format('woff'),
         url('../fonts/optima.ttf') format('truetype'),
         url('../fonts/optima.otf') format('truetype'),
         url('../fonts/optima.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
