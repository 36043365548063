.text__admin {
    font-size: 14px;
    line-height: 1.4;
    font-family: $ff-regular;
    color: $bg_main;
    h1,h2,h3,h4,h5,h6 {
        line-height: normal;
        font-family: $ff-regular;
        color: $bg_main;
        font-weight: 400;
        margin: 25px 0 15px;
    }
    h1 {
        font-size: 26px;
    }
    h2 {
        font-size: 24px;
    }
    h3 {
        font-size: 22px;
    }
    h4, h5, h6  {
        font-size: 20px;
    }
    p + p {
      margin: 15px 0 0;
    }
    p + ul,
    p + ol {
      margin: 15px 0 0;
    }
    ul + p,
    ol + p {
      margin: 15px 0 0;

    }
    p {
      margin: 0;
        a {
            display: inline;
            color: $bg_main;
            text-decoration: underline;
        }
        img, iframe {
            display: block;
            max-width: 100%;
            height: auto !important;
            margin: 0 auto;
        }
    }
    ul,ol {
        list-style-position: inside;
        li {
          font-size: calc(14px + 4 * ((100vw - 375px)/ 1650));
          line-height: calc(28px + 6 * ((100vw - 375px)/ 1650));
            a {
                display: inline;
                color: $bg_main;
                text-decoration: underline;
            }
            img, iframe {
                display: block;
                max-width: 100%;
                height: auto !important;
                margin: 0 auto;
            }
        }
        li+ li {
            margin-top: 5px;
        }
    }
    ul {
        list-style-type: disc;
    }
    ol {
        list-style-type: decimal;
    }
    &.big {
      font-size: calc(14px + 4 * ((100vw - 375px)/ 1650));
      line-height: calc(28px + 6 * ((100vw - 375px)/ 1650));
    }
}
