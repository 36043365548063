.form {
  &__title {
    font-size: 25px;
    text-align: center;
    text-transform: uppercase;
    line-height: calc(30 / 25);
    margin-bottom: 15px;
  }
  &__block {
    padding-top: 25px;
    margin-bottom: 25px;
    width: 100%;
    border-bottom: 1px solid rgba(204,204,204,.2);
  }
  &__flex {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -30px;
    margin-bottom: 45px;
  }
  &__item {
    padding: 0 30px;
    &--25 {
      width: 25%;
    }
    &--33 {
      width: 33.3%;
    }
    &--50 {
      width: 50%;
    }
    &--100 {
      width: 100%;
    }
  }
  &__btn {
    text-align: center;
  }
  &__success {
      color: $brown;
      font-size: 14px;
      text-transform: uppercase;
      text-align: center;
      padding-top: 45px;
      line-height: 1.2;
  }
}


.input {
  &__wrapper {
      position: relative;
      &.forms__error {
        .input__block {
          border: 1px solid $brown;
        }
      }
  }
  &__error {
    position: absolute;
    color: $brown;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 10px;
    left: 0;
    bottom: -15px;
    pointer-events: none;
  }
  &__block {
      border: 1px solid $grey_c;
      width: 100%;
  }
  &__input {
      display: block;
      width: 100%;
      height: 36px;
      padding: 7px 15px;
      margin: 0;
      font-size: 12px;
      line-height: 1;
      color: $bg_main;
      letter-spacing: 1px;
      cursor: pointer;
      border: none;
      border-radius: 0;
      background: 0 0;
      font-family: $ff-regular;
      &.textarea {
        height: 100px;
        padding-top: 15px;
      }
      &:focus {
        border-bottom: 2px solid rgba(204,204,204,.7);
      }
  }
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $bg_main;
  text-transform: uppercase;
}
::-moz-placeholder { /* Firefox 19+ */
  color: $bg_main;
  text-transform: uppercase;
}
:-ms-input-placeholder { /* IE 10+ */
  color: $bg_main;
  text-transform: uppercase;
}
:-moz-placeholder { /* Firefox 18- */
  color: $bg_main;
  text-transform: uppercase;
}

// ---------------------select----------------------------------
.selectric {
    background: $white;
    border: 0 solid $grey_c;
    .button {
        background: $white;
        color: $grey_c;
        &:after {
          top: -5px;
          border: none;
          border-top: 1px solid $brown;
          border-left: 1px solid $brown;
          width: 12px;
          height: 12px;
          -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
        }
    }
    .label {
      text-transform: uppercase;
      height: 36px;
      font-size: 12px;
      color: $bg_main;
    }
}
.selectric-open .selectric {
  border-bottom: 1px solid $grey_c;
}

.selectric-items {
  background: $text_main;
  border: 1px solid $grey_c;
  box-shadow: none;
}

.selectric-items li {
    &.select_placeholder {
        display: none;
    }
}


.selectric-items ul,
.selectric-items li {
    font-size: 16px;
    color: $bg_main;
}

.selectric-items li.highlighted,
.selectric-items li.selected,
.selectric-items li:hover,
.selectric-items li:focus,
.selectric-items li:active {
  color: $text_main;
  background: $brown;
}

.selectric-items li.select_placeholder {
  display: none;
}

.forms__error {
    .selectric {
        border: none;
    }
}

// ---------------------select----------------------------------
// ----------------checkbox-------------------------------------
.input__hidden {
  display: none;
}

.checkbox {
    &__cover + &__cover {
        margin: 5px 0 0;
    }
    &__label {
        position: relative;
        display: inline-block;
        line-height: 40px;
        vertical-align: text-top;
        font-size: 10px;
        text-transform: uppercase;
        font-family: $ff-regular;
        padding-left: 55px;
        color: $bg_main;
        cursor: pointer;
        span,a {
            line-height: 40px;
            vertical-align: middle;
            color: $bg_main;
        }
        a {
            text-decoration-color: $bg_main;
            text-decoration-line: underline;
        }
        &::before {
            content: "";
            display: block;
            width: 40px;
            height: 40px;
            border: 1px solid $grey_c;
            border-radius: 2px;
            background: $white;
            position: absolute;
            left: 0;
            top: 0;
        }
        &::after {
            content: none;
            display: block;
            width: 40px;
            height: 40px;
            background: $tr;
            border-radius: 2px;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
    &__checked {
        position: absolute;
        top: 7px;
        left: 15px;
        width: 10px;
        height: 17px;
        background-color: transparent;
        border-right: 1px solid $brown;
        border-bottom: 1px solid $brown;
        z-index: 1;
        opacity: 0;
        transform: rotate(45deg);
    }
    &__text {
        &--red {
            color: $brown;
        }
        &.js_hide {
            display: none;
        }
    }
    &__input {
       &:checked + .checkbox__label {
           &::after {
               content: '';
           }
           .checkbox__checked {
             opacity: 1;
           }
       }
   }
}


// ----------------checkbox-------------------------------------
