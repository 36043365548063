.contacts {
  &__subtitle {
    margin-bottom: calc(26px + 14 * ((100vw - 375px)/ 1650));
    font-size: calc(24px + 10 * ((100vw - 420px)/ 860));
    line-height: calc(26px + 14 * ((100vw - 375px)/ 1650));
  }
  &__block {
    margin-bottom: 3em;
  }
  &__map {
    position: relative;
    padding-bottom: 25%;
    height: 0;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  &__item {
    font-size: 16px;
    font-style: italic;
    &_title {
      text-transform: uppercase;
      font-size: 18px;
      margin: 0 0 30px;
    }
    &_address {
      font-style: normal;
    }
    &_block + &_block {
      margin: 20px 0 0;
    }
    &_phones {
      display: flex;
      flex-direction: column;
    }
    &_timework {
      margin-top: 40px;
      p {
        margin: 0;
      }
    }
  }
  &__link + &__link {
    margin: 7px 0 0;
  }
  &__link {
    color: $bg_main;
    @extend %transition;
    &.with_svg {
      display: flex;
      align-items: center;
      svg {
        display: block;
        fill: rgba($bg_main, .4);
        width: 14px;
        height: 14px;
        margin-right: 7px;
      }
    }
    &:hover,
    &:focus,
    &:active {
      color: $bg_main;
      text-decoration: underline;
    }
  }
  &__form {
    max-width: 1000px;
    margin: 0 auto;
    &_cover {
      margin-top: 5em;
      padding-top: 5em;
      border-top: 1px solid $grey_c;
    }
  }
  &__admin {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 25px;
  }
  &__team {
    &_cover {
      margin-top: 5em;
      padding-top: 5em;
      border-top: 1px solid $grey_c;
    }
    .title {
      text-transform: uppercase;
    }
    &_gallery_desc {
      font-size: 16px;
      line-height: 1.5;
      text-align: center;
      max-width: 610px;
      margin: 0 auto;
      margin-bottom: calc(26px + 14 * ((100vw - 375px)/ 1650));
    }
    &_paddind {
      padding: 0 10px;
    }
    &_item {
      height: 100%;
      border-left: 1px solid $grey_c;
    }
    &_info {
      padding: 24px;
    }
    &_title {
      letter-spacing: .15625em;
      font-size: 16px;
      text-transform: uppercase;
    }
    &_desc {
      margin: 15px 0 0;
      font-size: 16px;
      display: flex;
      flex-direction: column;
      * + * {
        margin: 10px 0 0;
      }
      .name {
        margin-bottom: 15px;
      }
      a {
        color: $bg_main;
        font-style: italic;
        @extend %transition;
        &:hover,
        &:focus,
        &:active {
          color: $bg_main;
          text-decoration: underline;
        }
      }
    }
  }
}


.social {
    &__holder {
        margin: 15px 0 0;
    }
    &__flex {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 -7.5px;
        &.center {
            justify-content: center;
        }
    }
    &__item {
        padding: 0 7.5px;
    }
    &__messenger {
        @include flex_center;
        .social__icon {
            display: block;
            width: 20px;
            height: 20px;
            svg {
              fill: rgba($bg_main, .4);
              @extend %transition;
            }
            &:hover,
            &:focus,
            &:active {
              svg {
                fill: rgba($bg_main, .8);
              }
            }
        }
    }
    &__network {
        @include flex_center;
        .social__icon {
            display: block;
            width: 20px;
            height: 20px;
            svg {
              fill: rgba($bg_main, .4);
              @extend %transition;
            }
        }
        &:hover,
        &:focus,
        &:active {
          svg {
            fill: rgba($bg_main, .8);
          }
        }
    }
}
