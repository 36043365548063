.slick-dots {
  li {
    button {
      width: 20px;
      height: 20px;
      position: relative;
      &:before,
      &:after {
        content: '';
        border: 1px solid $bg_main;
        border-radius: 10px;
        transition: width .3s,height .3s;
        width: 4px;
        height: 4px;
        position: absolute;
        top: 50%;
        left: 50%;
        background: $tr;
        transform: translate(-50%,-50%);
      }
      &:hover,
      &:focus,
      &:active {
        &::before {
            width: 10px;
            height: 10px;
        }
      }
    }
    &.slick-active {
      button {
        &::before {
            width: 10px;
            height: 10px;
        }
      }
    }
  }
}


.slick-arrow {
    position: absolute;
    width: 40px;
    top: 20px;
    bottom: 0;
    height: auto;
    transform: translate(0,0);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &::before {
      content: none;
      font-family: $ff-regular;
    }
    svg {
      display: block;
      width: 30px;
      height: 30px;
      fill: rgba($bg_main, .5);
      @extend %transition;
    }
    &.slick_arrow_prev {
      left: 0;

    }
    &.slick_arrow_next {
      right: 0;
    }

}
