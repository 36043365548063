.cat_unit {
  &__link {
      display: flex;
      flex-direction: column;
      color: $bg_main;
      @extend %transition;
      &:hover,
      &:focus,
      &:active {
        .cat_unit {
          &__img {
            box-shadow: 5px 5px 15px 4px rgba(0,0,0,0.2);
          }
          &__title {
            border-bottom: 2px solid rgba($bg_main, 0.2);
          }
        }
      }
  }
  &__img {
    display: block;
    @extend %transition;
  }
  &__title {
    border-bottom: 2px solid $tr;
    align-self: flex-start;
    padding-top: 15px;
    font-size: calc(18px + 13 * ((100vw - 375px)/ 1650));
    line-height: calc(20px + 13 * ((100vw - 375px)/ 1650));    
    @extend %transition;
  }
}
