.additions {
  &__section {
    color: $color_addit;
    background: $bg_addit;
  }
  &__block + &__block {
    // margin: 65px 0 0;
  }
  &__block {
      // position: relative;
      // z-index: 5;
  }
}

.additions__modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  z-index: 150;
  background: $black;
  &.js_active {
    opacity: 1;
    visibility: visible;
  }
  &_overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($black,.5);
    cursor: pointer;
  }
}

.no-touchevents {
  .additions_nav_section {
      .additions_link {
          &::after {
              transition: width .6s cubic-bezier(.19,1,.22,1);
          }
          &:hover,
          &:focus,
          &:active,
          &.js_active {
            color: $color_addit;
            &:after {
              width: calc(100% - 20px);
            }
          }
      }
  }
}

.additions_nav_section {
    position: absolute;
    left: 0;
    right: 0;
    top: calc(100vh - 65px);
    background: $nav_addit;
    z-index: 10;
    .additions_nav_scroll {
      top: -65px;
      overflow: hidden;
      transition: top .6s cubic-bezier(.39,.575,.565,1) .6s;
      left: 0;
      right: 0;
      z-index: 100;
      background: $nav_addit;
      &.js_fixed {
        position: fixed;
        overflow: visible;
        top: 0;
      }
    }
    .additions_nav {
      width: 100%;
      max-width: calc(100% * 10/12 - (20px - 20px * 10/12));
      margin: 0 auto;
      padding: 0 20px;
      height: 65px;
    }
    .additions_ul {
      position: relative;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      line-height: 1;
      width: 100%;
      height: 100%;
      margin: 0 -calc(20px / 2);
    }
    .additions_li {
        padding: 0 calc(20px / 2);
    }
    .additions_link {
      position: relative;
      display: flex;
      padding: 20px calc(20px / 2);
      background: $tr;
      font-size: calc(9px + 5 * ((100vw - 769px)/ 1256));
      text-transform: uppercase;
      font-family: $ff-regular;
      color: rgba($color_addit,.7);
      letter-spacing: 1px;
      transition: opacity .3s cubic-bezier(.19,1,.22,1);
      &::after {
        content: '';
        width: 0;
        height: 2px;
        background: rgba($color_addit,.2);
        bottom: 14px;
        left: 10px;
        position: absolute;
      }
      &.js_active {
        color: $color_addit;
        &:after {
          width: calc(100% - 20px);
        }
      }
    }
}

@media (max-width: 1023px) {
  .additions_nav_section {
     top: calc(100vh - 55px);
    .additions_nav {
      height: 55px;
    }
    .additions_ul {
      flex-direction: column;
      width: auto;
      height: auto;
    }
    .additions_link {
      display: none;
      opacity: 0;
      font-size: 13px;
      transition: opacity .8s cubic-bezier(.39,.575,.565,1);
      &.js_active {
          display: flex;
          opacity: 1;
      }
    }
  }
}


// ----------------main_image_with_title START-------------------------------------
.main_image_with_title {
    .additions__item {
      width: 100%;
      height: 100vh;
      min-height: 500px;
      position: relative;
    }
    .additions__img {
      overflow: hidden;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      &:after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        z-index: 2;
        background: rgba($black,.3);
        width: 100%;
        height: 100%;
      }
    }
    .img_addit {
      display: block;
      margin: 0;
      -o-object-fit: cover;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    .additions__info {
      overflow: hidden;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .additions__container {
      padding:  0 20px;
      width: 100%;
      text-align: center;
      position: relative;
      z-index: 2;
    }
    .additions__title {
      margin-bottom: calc(17px + 3 * ((100vw - 375px)/ 1650));
      font-size: calc(15px + 5 * ((100vw - 1200px)/ 825));
      line-height: 15px;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
    .additions__subtitle {
      font-size: calc(35px + 50 * ((100vw - 375px)/ 1650));
      line-height: calc(35px + 40 * ((100vw - 375px)/ 1650));
      text-transform: uppercase;
      max-width: 900px;
      margin: 0 auto;
    }
}

@media (min-width: 2025px) {
  .main_image_with_title {
    .additions__title {
        font-size: 20px;
        // line-height: 25px;
    }
    .additions__subtitle {
      line-height: 75px;
      font-size: 85px;
      max-width: calc(100% * 6/12 - (20px - 20px * 6/12));
    }
  }
}

@media (max-width: 1199px) {
  .main_image_with_title {
      .additions__title {
        font-size: 15px;
      }
   }
}

@media (max-width: 480px) {
  .main_image_with_title {
      .additions__subtitle {
        line-height: 35px;
        font-size: 35px;
      }
   }
}
// ----------------main_image_with_title FINISH------------------------------------
// -------------------video_on_photo START------------------------------------------
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba($color_addit, 0.7);
      box-shadow: 0 0 0 0 rgba($color_addit, 0.7);
  }
  70% {
      -webkit-box-shadow: 0 0 0 5px rgba($color_addit, 0);
      box-shadow: 0 0 0 5px rgba($color_addit, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba($color_addit, 0);
      box-shadow: 0 0 0 0 rgba($color_addit, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba($color_addit, 0.7);
    box-shadow: 0 0 0 0 rgba($color_addit, 0.7);
  }
  70% {
      -moz-box-shadow: 0 0 0 5px rgba($color_addit, 0);
      box-shadow: 0 0 0 5px rgba($color_addit, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba($color_addit, 0);
      box-shadow: 0 0 0 0 rgba($color_addit, 0);
  }
}

.video_on_photo {
  padding-bottom: 50px;
  .additions__item {
    width: 100%;
    padding: 0 0 50%;
    position: relative;
  }
  .additions__img {
    overflow: hidden;
    width: 100%;
    bottom: 16.66%;
    top: 0;
    left: 0;
    position: absolute;
    &:after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      z-index: 2;
      background: rgba($black,.3);
      width: 100%;
      height: 100%;
    }
  }
  .img_addit {
    display: block;
    margin: 0;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .additions__video_cover {
    position: absolute;
    height: 0;
    bottom: 0;
    padding-bottom: 31%;
    left: 0;
    width: 100%;
    z-index: 2;
  }
  .additions__video_poster {
    top: 0;
    right: calc(99.9% / 12 - (20px - 20px / 12));
    left: calc(99.9% / 12 - (20px - 20px / 12));
    position: absolute;
    width: auto;
    height: 100%;
  }
  .img_addit_poster {
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    -o-object-fit: cover;
    object-fit: cover;
    width: 99.9%;
    height: 100%;
  }
  .additions__video {
    top: 0;
    right: calc(99.9% / 12 - (20px - 20px / 12));
    left: calc(99.9% / 12 - (20px - 20px / 12));
    position: absolute;
    width: auto;
    height: 100%;
  }
  .video_addit {
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .additions__click {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    cursor: pointer;
  }
  .additions__icon {
    display: block;
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 3.2vw;
    height: 3.2vw;
    svg {
      display: block;
      width: 100%;
      height: 100%;
      fill: $color_addit;
    }
  }
  .additions__icon_top {
      display: block;
      width: 36px;
      height: 36px;
      cursor: pointer;
      left: 50%;
      transform: translateX(-50%);
      bottom: calc(100% + 110px);
      position: absolute;
      z-index: 3;
      border-radius: 50%;
      animation: pulse 2s infinite;
      svg {
        fill: $color_addit;
      }
  }
}
.modal_video_on_photo {
  .additions__modal_holder {
      position: relative;
      padding: 0;
      min-height: 100%;
  }
  .additions__modal_video {
      top: 65px;
      bottom: 65px;
      left: 0;
      position: absolute;
      width: 100%;
  }
  .additions__modal_video_addit {
      position: absolute;
      top: 0;
      left: 0;
      border: 0;
      -o-object-fit: cover;
      object-fit: cover;
      width: 100%;
      height: 100%;
  }
  .additions__modal_close {
    position: absolute;
    cursor: pointer;
    z-index: 3;
    top: 5px;
    left: auto;
    right: 5px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      fill: $color_addit;
      display: block;
      width: 16px;
      height: 16px;
    }
  }
}

@media (max-width: 767px) {
  .video_on_photo {
    .additions__item {
       height: 400px;
    }
    .additions__video_cover {
      height: 235px;
      padding: 0 20px;
    }
    .additions__video,
    .additions__video_poster {
        left: 20px;
        right: 20px;
        overflow: hidden;
    }
    .additions__icon_top {
      display: none;
    }
  }
}
// ---------------------video_on_photo FINISH---------------------------------------
// ----------------------mulpiple_text START ---------------------------------------
.mulpiple_text {
    // margin-bottom: 65px;
    .additions__item {
      padding-top: 4.625vw;
      padding-bottom: 4.625vw;
      max-width: 1650px;
      width: 100%;
      margin: 0 auto;
    }
    .additions__info {
      max-width: calc(100% * 8/12 - (20px - 20px * 8/12));
      width: calc(100% * 8/12 - (20px - 20px * 8/12));
      margin: 0 auto;
      padding: 0 20px;
      text-align: center;
    }
    .additions__title {
        margin-bottom: calc(26px + 14 * ((100vw - 375px)/ 1650));
        font-size: 34px;
        line-height: calc(26px + 14 * ((100vw - 375px)/ 1650));
        text-transform: uppercase;
    }
    .additions__subtitle {
      display: block;
      margin-bottom: calc(16px + 4 * ((100vw - 375px)/ 1650));
      font-size: calc(14px + 2 * ((100vw - 375px)/ 1645));
      text-transform: uppercase;
      line-height: 1;
      letter-spacing: 2px;
    }
    .additions__text {
      font-size: calc(14px + 4 * ((100vw - 375px)/ 1650));
      line-height: calc(26px + 6 * ((100vw - 375px)/ 1650));
      text-align: center;
      p {
        margin: 0;
      }
      p + p {
        margin: 30px 0 0;
      }
    }
    .additions__text_2 {
      -webkit-column-count: 2;
      column-count: 2;
      grid-column-gap: 3.42vw;
      -webkit-column-gap: 3.42vw;
      column-gap: 3.42vw;
      text-align: left;
    }
}

@media (max-width: 1299px) {
  .mulpiple_text {
    .additions__title {
      font-size: calc(24px + 10 * ((100vw - 420px)/ 860));
    }
  }
}

@media (max-width: 1023px) {
  .mulpiple_text {
      .additions__text_2 {
        webkit-column-count: 1;
        column-count: 1;
        grid-column-gap: 0;
        -webkit-column-gap: 0;
        column-gap: 0;
        text-align: center;
      }
      .additions__info {
        max-width: calc(100% * 1/1 - (10px - 10px * 1/1));
        width: calc(100% * 1/1 - (10px - 10px * 1/1));
        padding: 0 calc(99.9% / 12 - (10px - 10px / 12));
      }
  }
}

@media (max-width: 480px) {
  .mulpiple_text {
    .additions__subtitle {
      font-size: 14px;
    }
    .additions__title {
      font-size: 24px;
      line-height: 26px;
    }
    .additions__text {
      line-height: 28px;
      font-size: 14px;
    }
  }
}
// --------------------mulpiple_text FINISH-----------------------------------------
// ---------------video_on_background START-----------------------------------------
.video_on_background {
    .additions__item {
      padding-top: 4.625vw;
      padding-bottom: 4.625vw;
    }
    .additions__video_cover {
      position: relative;
      display: block;
      height: 0;
      padding: 0 0 30.42%;
      overflow: hidden;
      z-index: 2;
    }
    .additions__video {
      top: 0;
      right: calc(99.9% / 12 - (20px - 20px / 12));
      left: calc(99.9% / 12 - (20px - 20px / 12));
      position: absolute;
      width: auto;
      height: 100%;
    }
    .video_addit {
      position: absolute;
      top: 0;
      left: 0;
      border: 0;
      -o-object-fit: cover;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    .additions__video_poster {
      top: 0;
      right: calc(99.9% / 12 - (20px - 20px / 12));
      left: calc(99.9% / 12 - (20px - 20px / 12));
      position: absolute;
      width: auto;
      height: 100%;
    }
    .img_addit_poster {
      position: absolute;
      top: 0;
      left: 0;
      border: 0;
      -o-object-fit: cover;
      object-fit: cover;
      width: 99.9%;
      height: 100%;
    }
}

@media (max-width: 767px) {
  .video_on_background {
    .additions__video_cover {
      height: 235px;
      padding: 0 20px;
    }
    .additions__video,
    .additions__video_poster {
        left: 20px;
        right: 20px;
        overflow: hidden;
    }
  }
}
// ----------------video_on_background FINISH----------------------------------------
// -----------photo_text_left_right START--------------------------------------------
.photo_text_left_right {
    .additions__item {
      padding-top: 4.625vw;
      padding-bottom: 4.625vw;
    }
    .additions__flex {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    .additions__info_cover {
      width: 42%;
      padding: 0 calc(99.9% / 12 - (20px - 20px / 12));
    }
    .additions__title {
      margin-bottom: calc(28px + 34 * ((100vw - 375px)/ 1650));
      font-size: calc(18px + 8 * ((100vw - 375px)/ 1650));
      text-transform: uppercase;
      line-height: calc(22px + 6 * ((100vw - 375px)/ 1650));
    }
    .additions__text {
      font-size: calc(14px + 4 * ((100vw - 375px)/ 1650));
      line-height: calc(28px + 6 * ((100vw - 375px)/ 1650));
      p {
        margin: 0;
      }
      p + p {
        margin: 20px 0 0;
      }
    }
    .additions__img_cover {
        width: 58%;
    }
    .additions__img_cover_left {
       order: -1;
    }
    .additions__img {
      max-height: 30.4vw;
      overflow: hidden;
      display: flex;
      align-items: center;
    }
    .img_addit {
        display: block;
        width: 100%;
        height: auto;
    }
}

@media (max-width: 1023px) {
  .photo_text_left_right {
      .additions__flex {
        flex-direction: column;
        padding: 0 calc(99.9% / 12 - (20px - 20px / 12));
      }
      .additions__img_cover {
        order: -1;
        margin-bottom: 47px;
        width: 100%;
      }
      .additions__img {
        max-height: 100%;
      }
      .additions__info_cover {
          padding: 0 20px 17px;
          width: 100%;
      }
      .additions__title {
        margin-bottom: 18px;
      }
  }
}

@media (max-width: 767px) {
  .photo_text_left_right {
      .additions__flex {
        padding: 0 20px;
      }
  }
}
@media (max-width: 480px) {
  .photo_text_left_right {
    .additions__title {
      line-height: 22px;
      font-size: 18px;
    }
    .additions__text {
      line-height: 28px;
      font-size: 14px;
    }
  }
}
// --------------photo_text_left_right FINISH---------------------------------------
// ---------------six_gallery START-------------------------------------------------
.six_gallery {
  .additions__item {
    padding-top: 4.625vw;
    padding-bottom: 4.625vw;
  }
  .additions__slider_cover {
    position: relative;
    display: block;
    height: 0;
    padding: 0 0 45.5%;
    overflow: hidden;
  }
  .additions__slider {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin-bottom: 0;
      // cursor: pointer;
  }
  .slick-slide {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 45.8vw;
  }
  .six_gallery_slide {
    width: 50%;
    &_1 {
      height: 50%;
    }
    &_2 {
      height: 50%;
    }
    &_3 {
      height: 100%;
    }
    &_4 {
      height: 100%;
    }
    &_5 {
      height: 50%;
    }
    &_6 {
      height: 50%;
    }
  }
  .additions__slide {
    width: 100%;
    height: 100%;
  }
  .additions__link {
      display: block;
      width: 100%;
      height: 100%;
  }
  .additions__img {
    width: 100%;
    height: 100%;
  }
  .img_addit {
    display: block;
    margin: 0;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .slick-dots {
    top: 0;
    bottom: 0;
    right: 20px;
    width: auto;
    left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    li {
      pointer-events: none;
      button {
        width: 20px;
        height: 20px;
        position: relative;
        &:before,
        &:after {
          content: '';
          border: 1px solid $color_addit;
          border-radius: 10px;
          transition: width .3s,height .3s;
          width: 4px;
          height: 4px;
          position: absolute;
          top: 50%;
          left: 50%;
          background: $tr;
          transform: translate(-50%,-50%);
        }
        &:hover,
        &:focus,
        &:active {
          &::before {
              width: 10px;
              height: 10px;
          }
        }
      }
      &.slick-active {
        button {
          &::before {
              width: 10px;
              height: 10px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .six_gallery {
    .additions__slider_cover {
      height: 50vh;
      padding: 0;
    }
    .slick-slide {
      height: 50vh;
      > div {
        width: 100%;
        height: 100%;
      }
    }
    .six_gallery_slide {
      width: 100%;
      height: 100%;
    }
    .slick-dots {
      flex-direction: row;
      bottom: 20px;
      left: 0;
      top: auto;
      width: 100%;
      li {
        pointer-events: all;
      }
    }
  }
}
// ---------------six_gallery FINISH------------------------------------------------
// ----------------horizontal_gallery START-----------------------------------------
.horizontal_gallery {
    .additions__item {
      // padding-top: 4.625vw;
      // padding-bottom: 4.625vw;
    }
    .additions__slider_cover {
        height: 90vh;
        overflow: hidden;
    }
    .additions__slider {
      width: 80vw;
      .slick-list {
        overflow: visible;
      }
      &.js_center {
        margin: 0 auto;
      }
      &.js_right {
        margin: 0 auto 0 0;
      }
      &.js_left {
        margin: 0 0 0 auto;
      }
    }
    .additions__slide {
      position: relative;
      height: 90vh;
      cursor: pointer;
      &:after {
        content: '';
        position: absolute;
        right: 0;
        height: 100%;
        top: 0;
        left: 0;
        opacity: .4;
        background-color: #161a11;
        transition: .3s;
        pointer-events: none;
      }
      .additions__img {
        pointer-events: none;
      }
      &.slick-current,
      &.slick-active {
        .additions__img {
          pointer-events: auto;
        }
        &:after {
          opacity: 0;
        }
      }
      &:hover,
      &:focus,
      &:active {
        &:after {
          opacity: 0;
        }
      }
    }
    .additions__img {
        display: flex;
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
    }
    .img_addit {
      display: block;
      margin: 0;
      -o-object-fit: cover;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
}

@media (max-width: 1199px) {
  .horizontal_gallery {
    .additions__slide {
      padding: 0 5px;
      &:after {
        right: 5px;
        left: 5px;
      }
    }
    .additions__slider {
      &.js_center {
        margin: 0 auto;
      }
      &.js_right {
        margin: 0 auto 0 0;
        margin-left: -5px;
      }
      &.js_left {
        margin: 0 0 0 auto;
        margin-right: -5px;
      }
    }
  }
}

@media (max-width: 1023px) {
  .horizontal_gallery {
    .additions__slider_cover {
      height: 50vh;
    }
    .additions__slide {
      height: 50vh;
    }
  }
}
// -----------------horizontal_gallery FINISH------------------------------------------
// -------------------tth_text START-------------------------------------------------
.tth_text {
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  .additions__item {
    padding-top: 4.625vw;
    padding-bottom: 4.625vw;
    width: 100%;
  }
  .additions__info_cover {
    margin: 0 auto;
    padding: 0 calc(99.9% / 12 - (20px - 20px / 12));
    position: relative;
    z-index: 5;
  }
  .additions__text {
    font-size: 2.25rem;
    line-height: 3.15rem;
    margin-bottom: 165px;
    width: 50%;
    color: #f8f7f4;
    p {
      margin: 0;
    }
    p + p {
      margin: 30px 0 0;
    }
  }
  .additions__flex {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
  .additions__flex_item {
    padding: 0 15px;
    width: 25%;
  }
  .additions__chars {
    padding: 10px 0 0 0;
    color: #f8f7f4;
    height: 100%;
    display: flex;
    flex-direction: column;
    &.js_opacity {
      opacity: 0;
    }
  }
  .additions__flex_item  + .additions__flex_item  {
    .additions__chars {
      padding-left: 30px;
      border-left: 1px solid rgba(248, 247, 244, .2);
    }
  }
  .additions__chars_value {
    font-size: 5rem;
    line-height: 5rem;
    margin-top: auto;
  }
  .additions__chars_title {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 16px;
  }
  .additions__chars_name {
    font-weight: 600;
    color: #f8f7f4;
  }
  .additions__chars_code {
    color: rgba(249, 249, 249, .5);
    white-space: nowrap;
  }
  .additions__img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.3;
    filter: blur(10px);
  }
}

@media (max-width: 1299px) {
  .tth_text {
    .additions__flex_item {
      width: 33.3%;
    }
  }
}

@media (max-width: 1199px) {
  .tth_text {
    .additions__text {
      width: 100%;
    }
  }
}

@media (max-width: 1023px) {
  .tth_text {
    min-height: 50vh;
    .additions__text {
      margin-bottom: 60px;
      font-size: 1.375rem;
      line-height: 2rem;
    }
    .additions__chars_value {
      font-size: 3.625rem;
      line-height: 3.625rem;
    }
  }
}

@media (max-width: 767px) {
  .tth_text {
    .additions__flex_item {
      width: 100%;
    }
    .additions__chars {
      border-left: 1px solid rgba(248, 247, 244, .2);
      padding-left: 40px;
      padding-bottom: 65px;
    }
    .additions__item {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }
}
// ------------------tth_text FINISH-------------------------------------------------
// --------------------colors START----------------------------------------------------
.colors {
  .additions__item {
    padding-top: 4.625vw;
    padding-bottom: 4.625vw;
    background: radial-gradient(at 70%, at 54%,#9fa2a9,#212325);
    background: radial-gradient(at 70% 54%,#9fa2a9,#212325);
    background-size: 100% 100%;
  }
  .additions__container {
    padding: 0 15px;
    max-width: calc(100% - 50px);
    margin: 0 auto;
  }
  .additions__info {
    width: 58.33%;
  }
  .additions__subtitle {
    margin-bottom: 32px;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.875rem;
  }
  .additions__title {
    margin-bottom: 20px;
    font-size: 2.25rem;
    line-height: 3.125rem;
    letter-spacing: .4px;
  }
  .additions__block {
    margin: 50px 0 0;
  }
  .additions__flex {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
  .additions__models_nav__cover {
    padding: 0 15px;
    width: 33.33%;
  }
  .additions__models_nav {

  }
  .additions__models_nav__ul {
    display: flex;
    flex-direction: column;
  }
  .additions__models_nav__li {
      margin-bottom: 12px;
  }
  .additions__models_nav__link {
    padding: 0;
    opacity: .5;
    font-family: $ff-regular;
    color: #f7f8f4;
    cursor: pointer;
    border: none;
    background: transparent;
    transition: all 0.3s ease;
    font-size: 24px;
    font-weight: normal;
    line-height: 40px;
    &.js_active {
      opacity: 1;
    }
  }
  .additions__models_img__cover {
    padding: 0 15px;
    width: 66.66%;
  }
  .additions__models_img {
    top: 50%;
    transform: translateY(-50%);
    background-image: none;
    position: relative;
    height: 0;
    padding-top: 50%;
  }
  .additions__img {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.6s ease;
    visibility: hidden;
    &.js_active {
      opacity: 1;
      visibility: visible;
    }
  }
  .img_addit {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: all 0.6s ease;
  }
}

@media (max-width: 1199px){
    .colors {
      .additions__info {
        width: 100%;
      }
      .additions__subtitle {
        margin-bottom: 15px;
        font-weight: bold;
      }
      .additions__title {
        margin-bottom: 10px;
        font-size: 1.75rem;
        line-height: 2rem;
      }
      .additions__block {
        margin: 0;
      }
      .additions__models_nav__cover {
        width: 100%;
      }
      .additions__models_nav {
        padding: 25px 0 35px 0;
        margin: 0 -15px;
      }
      .additions__models_nav__ul {
        flex-direction: row;
      }
      .additions__models_nav__li {
        margin-bottom: 0;
      }
      .additions__models_nav__link {
        padding: 0 15px;
        font-size: 16px;
        font-weight: bold;
        line-height: 30px;
        white-space: nowrap;
      }
      .additions__models_img__cover {
        width: 100%;
      }
      .additions__models_img {
        // width: calc(100% + 80px);
        // margin-left: - 40px;
        top: 0;
        transform: translateY(0);
      }
    }
}

@media (max-width: 1023px) {
    .colors  {
      .additions__container {
        max-width: calc(100% - 12px);
      }
      .additions__models_img {
        // width: calc(100% + 32px);
        // margin-left: - 16px;
      }
    }
}
// --------------------colors FINISH---------------------------------------------------
// -----------------fullscreen_photo START---------------------------------------------
.fullscreen_photo {
  .additions__item {
    padding-top: 4.625vw;
    padding-bottom: 4.625vw;
  }
  .additions__container {
    padding: 0 15px;
    max-width: calc(100% - 50px);
    margin: 0 auto;
  }
  .additions__block {
    padding-bottom: 45%;
    position: relative;
  }
  .additions__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}
@media (max-width: 1199px) {
  .fullscreen_photo {
    .additions__img {
      background-attachment: inherit;
    }
    .additions__block {
      padding-bottom: 60%;
    }
    .img_addit {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}
@media (max-width: 1023px) {
  .fullscreen_photo {
    .additions__container {
      max-width: calc(100% - 12px);
    }
  }
}
@media (max-width: 767px) {
  .fullscreen_photo {
    .additions__block {
      padding-bottom: 65%;
    }
    .additions__container {
      max-width: 100%;
      padding: 0;
    }
  }
}
// -----------------fullscreen_photo FINISH--------------------------------------------
// -------------------------photo_text_block START-------------------------------------
.photo_text_block {
  .additions__item {
    padding-top: 80px;
  }
  .additions__item_light {
    background: #f8f7f4;
    color: #161a21;
    .additions__svg {
      background: #fff;
      svg {
        fill: #161a21;
      }
    }
  }
  .additions__item_dark {
    color: #f8f7f4;
    background: #161a21;
    .additions__svg {
      background: #080B11;
      svg {
        fill: #f8f7f4;
      }
    }
  }
  .additions__container {
    padding: 0 15px;
    max-width: calc(100% - 50px);
    margin: 0 auto;
  }
  .additions__info {
      width: 50%;
  }
  .additions__subtitle {
      margin-bottom: 30px;
      font-size: 16px;
      line-height: 1.86rem;
      font-weight: 600;
  }
  .additions__title {
      margin-bottom: 120px;
      font-size: 3.625rem;
      line-height: 3.625rem;
  }
  .additions__flex {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
  .additions__text_cover {
    padding: 0 15px;
    width: 33.33%;
    &.end {
      align-self: flex-end;
    }
  }
  .additions__img_cover {
    padding: 0 15px;
    width: 66.67%;
  }
  .additions__img {
      height: 75vh;
      min-height: 520px;
      margin-bottom: 4px;
      position: relative;
      overflow: hidden;
  }
  .img_addit {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .additions__text_title {
      font-size: 16px;
      line-height: 46px;
      font-weight: 600;
      margin-bottom: 16px;
  }
  .additions__text_text {
      font-size: 16px;
      line-height: 30px;
      margin-bottom: 22px;
      p {
        margin: 0;
      }
      p + p {
        margin: 15px 0 0;
      }
  }
  .additions__author {
    display: flex;
    margin:  0 -15px;
  }
  .additions__author_img__cover {
    padding: 0 15px;
    flex: 0 0 auto;
  }
  .additions__author_img {
    width: 64px;
    height: 84px;
    position: relative;
    overflow: hidden;
  }
  .img_addit_author {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .additions__author_info {
    padding: 0 15px;
  }
  .additions__author_name {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 15px;
  }
  .additions__author_post {
      font-size: 16px;
      font-weight: 300;
  }
  .additions__blockquote {
    margin-bottom: 40px;
    font-size: 1.5rem;
    line-height: 2.5rem;
    position: relative;
  }
  .additions__svg {
    position: absolute;
    top: -60px;
    left: -30px;
    display: flex;
    width: 78px;
    height: 78px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    z-index: -1;
    svg {
      display: block;
      width: 14px;
      height: 14px;
    }
  }
  .additions__sticky {
    position: sticky;
    top: 135px;
  }
  .additions__img--padding {
    padding-bottom: 80px;
  }
  .additions__flex--padding {
    position: relative;
    top: -80px;
  }
}

@media (max-width: 1199px) {
  .photo_text_block {
    .additions__title {
      font-size: 2.25rem;
      line-height: 3.15rem;
      margin-bottom: 55px;
    }
    .additions__subtitle {
      margin-bottom: 0;
    }
    .additions__info {
      width: 100%;
      padding-top: 35px;
    }
    .additions__text_cover {
      width: 100%;
      padding-bottom: 60px;
      &.end {
        order: 1;
        padding: 30px 15px 35px 15px;
      }
    }
    .additions__img_cover {
      width: 100%;
    }
    .additions__blockquote {
        margin-bottom: 25px;
        font-size: 1.375rem;
        line-height: 2rem;
    }
    .additions__svg {
      width: 48px;
      height: 48px;
      position: static;
      margin-bottom: 15px;
      svg {
        width: 12px;
        height: 12px;
      }
    }
    .additions__img--padding {
      padding-bottom: 0;
    }
    .additions__flex--padding {
      top: 0;
    }
    .additions__item {
      padding-top: 4.625vw;
      padding-bottom: 4.625vw;
    }
    .additions__text_title {
      line-height: 30px;
      margin-bottom: 7px;
    }
    .additions__text_text {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 1023px) {
  .photo_text_block {
    .additions__container {
      max-width: calc(100% - 12px);
    }
    // .additions__img {
    //   height: auto;
    //   min-height: 100%;
    //   overflow: visible;
    // }
    // .img_addit {
    //   position: static;
    //   display: block;
    //   width: 100%;
    //   height: auto;
    // }
  }
}

@media (max-width: 767px) {
  .photo_text_block {
    .additions__container {
      max-width: 100%;
    }
    .additions__title {
      margin-bottom: 35px;
    }
    .additions__img_cover {
      width: 100%;
      padding: 0;
      margin-bottom: 4px;
    }
    .additions__img {
      min-height: 100%;
    }
  }
}
// ------------------------photo_text_block FINISH-------------------------------------
