.news {
  &__top {
    position: relative;
    display: block;
    height: 0;
    padding: 0 0 24%;
    overflow: hidden;
    background: rgba($bg_main, .4);
    &_img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      @include flex_center;
      .img_responsive {
        display: block;
        margin: 0;
        -o-object-fit: cover;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
      &:after {
        content: '';
        z-index: 2;
        background: rgba($bg_main,.4);
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        position: absolute;
      }
    }
    &_text {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      @include flex_center;
    }
  }
  &__cover {
    padding-top: 4.625vw;
    padding-bottom: 4.625vw;
  }
  &__preinfo {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    font-size: 14px;
    margin-bottom: 30px;
  }
  &__postinfo {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    font-size: 14px;
    margin-top: 30px;
  }
  &__item {
    font-size: calc(14px + 4 * ((100vw - 375px)/ 1650));
    line-height: calc(28px + 6 * ((100vw - 375px)/ 1650));
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    border-bottom: 1px solid $grey_c;
    &_date {
      font-size: calc(12px + 4 * ((100vw - 375px)/ 1650));
      line-height: 1.75;
      font-style: italic;
      display: block;
    }
    &_title {

    }
    &_link {
        font-size: 15px;
        line-height: 2em;
        text-transform: uppercase;
        border-bottom: 2px solid rgba($bg_main,.2);
        color: $bg_main;
        font-family: $ff-regular;
        &:hover,
        &:focus,
        &:active {
          border-bottom: 2px solid rgba($bg_main,.1);
        }
    }
    &_text {
      font-size: calc(12px + 4 * ((100vw - 375px)/ 1650));
      line-height: 1.75;
      p {
        margin: 0;
      }
    }
  }
}

.new_item {
  &__title {
    margin-bottom: calc(28px + 34 * ((100vw - 375px)/ 1650));
    font-size: calc(18px + 8 * ((100vw - 375px)/ 1650));
    text-transform: uppercase;
    line-height: calc(22px + 6 * ((100vw - 375px)/ 1650));
  }
  &__date {
    display: block;
    margin-bottom: 1em;
    font-size: calc(14px + 4 * ((100vw - 375px)/ 1650));
    line-height: calc(28px + 6 * ((100vw - 375px)/ 1650));
    font-style: italic;
  }
  &__img {
    margin-bottom: 3em;
  }
  &__video {
    margin-top: 3em;
  }
  &__gallery {
    margin: 0 -10px;
    margin-top: 3em;
    .slick-slide > div {
      padding: 0 10px;
    }
  }
  &__photo {
    display: block;
  }
}
