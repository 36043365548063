.main_slider {
    &__section {
        &.active {
            .main_slider {
                &__text {
                    opacity: 1;
                    transform: translateY(0);
                }
                &__title {
                    opacity: 1;
                    transform: translateY(0);
                }
                &__btn {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }
    }
    &__block {
        padding: 0 calc(99.9% / 12 - (20px - 20px / 12));
        position: relative;
        overflow: hidden;
        height: 100%;
        max-height: 100vh;
        height: 100vh;
        @include flex_center;
    }
    &__video {
        position: absolute;
        top: 0;
        left: 0;
        border: 0;
        -o-object-fit: cover;
        object-fit: cover;
        width: 100%;
        height: 100%;
        max-height: 100vh;
    }
    &__info {
        width: 100%;
        max-width: 1625px;
        margin: auto;
        @include flex_center;
    }
    &__link {
        display: block;
        width: 100%;
        max-width: 800px;
        text-align: center;
        padding: 40px;
        color: $text_main;
        position: relative;
        z-index: 2;
    }
    &__text {
        margin-bottom: 10px;
        letter-spacing: 2px;
        font-size: calc(12px + 4 * ((100vw - 375px)/ 1650));
        color: $text_main;
        text-transform: uppercase;
        transform: translateY(60px);
        opacity: 0;
        transition: opacity 1s linear .4s,transform 1s cubic-bezier(.215,.61,.355,1) .4s,-webkit-transform 1s cubic-bezier(.215,.61,.355,1) .4s;
        p {
            margin: 0;
        }
    }
    &__title {
        line-height: 1;
        font-size: calc(35px + 30 * ((100vw - 375px)/ 1650));
        margin-bottom: 20px;
        color: $text_main;
        text-transform: uppercase;
        transform: translateY(60px);
        opacity: 0;
        transition: opacity 1s linear .625s,transform 1s cubic-bezier(.215,.61,.355,1) .625s,-webkit-transform 1s cubic-bezier(.215,.61,.355,1) .625s;
    }
    &__btn {
        line-height: 1;
        height: 40px;
        padding: 0 20px;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        border-radius: 3px;
        @include flex_center;
        display: inline-flex;
        cursor: pointer;
        font-size: 10px;
        width: auto;
        text-align: center;
        background: $tr;
        border: 2px solid rgba($white, .2);
        min-width: 160px;
        transform: translateY(60px);
        opacity: 0;
        transition: opacity 1s linear .85s,transform 1s cubic-bezier(.215,.61,.355,1) .85s,-webkit-transform 1s cubic-bezier(.215,.61,.355,1) .85s;
    }
    &__preloader {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 100;
        color: #fff;
        -webkit-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        display: block;
        width: 32px;
        height: 32px;
        z-index: 0;
    }
    &__loader {
        position: relative;
        display: block;
        -webkit-animation-name: loader-animation;
        animation-name: loader-animation;
        -webkit-animation-duration: 2s;
        animation-duration: 2s;
        -webkit-animation-timing-function: cubic-bezier(.445,.05,.55,.95);
        animation-timing-function: cubic-bezier(.445,.05,.55,.95);
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-direction: forward;
        animation-direction: forward;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        width: 100%;
        height: 100%;
        svg {
            fill: $text_main;
        }
    }
    &__poster {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}

#fp-nav,
.fp-slidesNav {
    li {
        &:last-child {
            display: none;
            opacity: 0;
        }
    }
    a {
        width: 20px;
        height: 20px;
        position: relative;
        &::after,
        &::before {
            content: '';
            border: 1px solid $text_main;
            border-radius: 10px;
            transition: width .3s,height .3s;
            width: 4px;
            height: 4px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
        &:hover,
        &:focus,
        &:active,
        &.active {
            &::before {
                width: 10px;
                height: 10px;
            }
        }
    }
    span {
        display: none;
    }
}

#fp-nav {
  z-index: 50;
}
