@charset "UTF-8";
/* Slider */
@import url(~lightcase/src/css/lightcase.css);
@import url(~fullpage.js/dist/jquery.fullpage.min.css);
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.selectric-wrapper {
  position: relative;
  cursor: pointer; }

.selectric-responsive {
  width: 100%; }

.selectric {
  border: 1px solid #DDD;
  border-radius: 0px;
  background: #F8F8F8;
  position: relative;
  overflow: hidden; }
  .selectric .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 38px 0 10px;
    font-size: 12px;
    line-height: 38px;
    color: #444;
    height: 38px;
    user-select: none; }
  .selectric .button {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 38px;
    height: 38px;
    line-height: 38px;
    background-color: #F8f8f8;
    color: #BBB;
    text-align: center;
    font: 0/0 a;
    *font: 20px/38px Lucida Sans Unicode, Arial Unicode MS, Arial; }
    .selectric .button:after {
      content: " ";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 0;
      height: 0;
      border: 4px solid transparent;
      border-top-color: #BBB;
      border-bottom: none; }

.selectric-focus .selectric {
  border-color: #aaaaaa; }

.selectric-hover .selectric {
  border-color: #c4c4c4; }
  .selectric-hover .selectric .button {
    color: #a2a2a2; }
    .selectric-hover .selectric .button:after {
      border-top-color: #a2a2a2; }

.selectric-open {
  z-index: 9999; }
  .selectric-open .selectric {
    border-color: #c4c4c4; }
  .selectric-open .selectric-items {
    display: block; }

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  user-select: none; }

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0; }
  .selectric-hide-select select {
    position: absolute;
    left: -100%; }
  .selectric-hide-select.selectric-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10; }
    .selectric-hide-select.selectric-is-native select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: none;
      z-index: 1;
      box-sizing: border-box;
      opacity: 0; }

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important; }

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important; }

/* Items box */
.selectric-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #F8F8F8;
  border: 1px solid #c4c4c4;
  z-index: -1;
  box-shadow: 0 0 10px -6px; }
  .selectric-items .selectric-scroll {
    height: 100%;
    overflow: auto; }
  .selectric-above .selectric-items {
    top: auto;
    bottom: 100%; }
  .selectric-items ul, .selectric-items li {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 12px;
    line-height: 20px;
    min-height: 20px; }
  .selectric-items li {
    display: block;
    padding: 10px;
    color: #666;
    cursor: pointer; }
    .selectric-items li.selected {
      background: #E0E0E0;
      color: #444; }
    .selectric-items li.highlighted {
      background: #D0D0D0;
      color: #444; }
    .selectric-items li:hover {
      background: #D5D5D5;
      color: #444; }
  .selectric-items .disabled {
    filter: alpha(opacity=50);
    opacity: 0.5;
    cursor: default !important;
    background: none !important;
    color: #666 !important;
    user-select: none; }
  .selectric-items .selectric-group .selectric-group-label {
    font-weight: bold;
    padding-left: 10px;
    cursor: default;
    user-select: none;
    background: none;
    color: #444; }
  .selectric-items .selectric-group.disabled li {
    filter: alpha(opacity=100);
    opacity: 1; }
  .selectric-items .selectric-group li {
    padding-left: 25px; }

html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

body {
  margin: 0; }

article,
aside,
footer,
header,
nav,
section {
  display: block; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

figcaption,
figure,
main {
  /* 1 */
  display: block; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

b,
strong {
  font-weight: inherit; }

b,
strong {
  font-weight: bolder; }

code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

dfn {
  font-style: italic; }

mark {
  background-color: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

audio,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

img {
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

button,
input {
  /* 1 */
  overflow: visible; }

button,
select {
  /* 1 */
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */ }

progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */ }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

details,
menu {
  display: block; }

summary {
  display: list-item; }

canvas {
  display: inline-block; }

template {
  display: none; }

[hidden] {
  display: none; }

@font-face {
  font-family: 'optimaregular';
  src: url("../fonts/optima.eot");
  src: url("../fonts/optima.eot?#iefix") format("embedded-opentype"), url("../fonts/optima.woff") format("woff"), url("../fonts/optima.ttf") format("truetype"), url("../fonts/optima.otf") format("truetype"), url("../fonts/optima.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

#up_button, .my_btn, .pagination_cover .pagination li span, .pagination_cover .pagination li a, .modal__close svg, .slick-arrow svg, .cat_unit__link, .cat_unit__img, .cat_unit__title, .search__input, .contacts__link, .contacts__team_desc a, .social__messenger .social__icon svg, .social__network .social__icon svg {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

@keyframes loader-animation {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@-webkit-keyframes loader-animation {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none; }

*:before, *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

html {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 100%;
  margin: 0;
  padding: 0; }

p iframe, p img {
  width: auto !important;
  margin: 0 auto;
  max-width: 100%;
  display: inline-block;
  height: auto !important; }

p img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto; }

p iframe {
  min-height: 400px;
  width: 100% !important;
  margin: 0 auto; }

body {
  font-size: 12px;
  font-family: "optimaregular", sans-serif;
  background-color: #000;
  color: #fff;
  margin: 0;
  padding: 0;
  line-height: normal;
  margin: 0 auto;
  min-height: 100%;
  position: relative;
  width: 100%;
  overflow-x: hidden; }
  body.overflow {
    overflow: hidden; }
  body.body__main {
    background-color: #000;
    color: #fff; }
  body.body__page {
    background-color: #fff;
    color: #000; }

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: 1.2;
  font-weight: normal; }

a {
  text-decoration: none; }

a, label {
  -webkit-tap-highlight-color: transparent; }

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0; }

input, button {
  border: none;
  outline: none;
  font-family: "optimaregular", sans-serif;
  cursor: pointer; }
  input:hover, input:focus, input:active, input:focus-within, button:hover, button:focus, button:active, button:focus-within {
    border: none;
    outline: none !important;
    -webkit-box-shadow: none;
    box-shadow: none; }

button {
  text-align: center; }

button::-moz-focus-inner {
  border: 0 !important; }

input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 50px #fff !important; }

textarea {
  resize: none;
  border: none;
  outline: none; }
  textarea:hover, textarea:focus, textarea:active {
    border: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none; }

video {
  display: block; }

a:hover, a:focus, a:active, a:visited {
  text-decoration: none;
  outline: none; }

.site {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  min-height: 100%; }
  .site.js_active {
    overflow: hidden; }

.overlay_main {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  bottom: 0;
  background: #000;
  opacity: 0;
  cursor: pointer;
  z-index: -1; }

.main-wrap {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -moz-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  position: relative; }

.main-wrap__page {
  display: flex;
  flex-direction: column; }

#up_button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: fixed;
  right: 30px;
  bottom: 30px;
  visibility: hidden;
  z-index: 7;
  border-radius: 4px;
  background: #fff;
  padding: 0;
  cursor: pointer; }
  #up_button svg {
    width: 20px;
    height: 20px; }
  #up_button.js_visible {
    visibility: visible; }
  #up_button:hover svg, #up_button:focus svg, #up_button:active svg {
    fill: #fff; }

.img_responsive {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto; }
  .img_responsive.big {
    width: 100%; }
  .img_responsive.not_margin {
    margin: 0; }

.site-svg {
  display: block;
  width: 100%;
  height: 100%; }

.section_white {
  background: #fff; }

.container-cover {
  width: 100%;
  max-width: 1655px;
  padding: 0 15px;
  margin: 0 auto; }

.container-relative {
  position: relative; }

.container-max {
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  position: relative; }

.container-900 {
  width: 100%;
  max-width: 900px;
  padding: 0 15px;
  margin: 0 auto; }

.container-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .container-flex.nowrap {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .container-flex.between {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .container-flex.start {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  .container-flex.column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }
  .container-flex.baseline {
    -webkit-box-align: baseline;
    -webkit-align-items: baseline;
    -moz-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline; }
  .container-flex.center {
    justify-content: center; }
  .container-flex.end {
    justify-content: flex-end; }

.flex-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.no_padding {
  padding: 0; }

.block_20 {
  width: 20%; }

.block_25 {
  width: 25%; }

.block_33 {
  width: 33.3%; }

.block_50 {
  width: 50%; }

.block_30 {
  width: 30%; }

.block_70 {
  width: 70%; }

.block_100 {
  width: 100%; }

.margin_minus--10 {
  margin: 0 -10px; }
  .margin_minus--10 > .block {
    padding: 0 10px; }

.margin_minus--15 {
  margin: 0 -15px; }
  .margin_minus--15 > .block {
    padding: 0 15px; }

.block_holder.margin_minus--30 {
  margin-bottom: -30px; }
  .block_holder.margin_minus--30 > .margin_minus--15 > .block {
    padding-bottom: 30px; }

.block_holder.margin_minus--20 {
  margin-bottom: -20px; }
  .block_holder.margin_minus--20 > .margin_minus--10 > .block {
    padding-bottom: 20px; }

.title {
  line-height: 1.3;
  color: #fff;
  font-family: "optimaregular", sans-serif; }
  .title--black {
    color: #000; }
  .title_center {
    text-align: center; }
  .title__page {
    position: relative;
    z-index: 3;
    font-size: calc(26px + 34 * ((100vw - 375px)/ 1650));
    text-align: center;
    text-transform: uppercase;
    line-height: calc(26px + 28 * ((100vw - 375px)/ 1650)); }
  .title__car {
    font-size: calc(18px + 34 * ((100vw - 375px)/ 1650));
    line-height: calc(20px + 34 * ((100vw - 375px)/ 1650));
    margin-bottom: 20px; }

@media (max-width: 575px) {
  .title__page {
    line-height: 26px;
    font-size: 26px; }
  .title__car {
    font-size: 24px;
    line-height: 24px; } }

.link_block {
  display: block; }

.my_btn {
  display: inline-block;
  line-height: 1;
  font-size: 10px;
  font-family: "optimaregular", sans-serif;
  font-weight: bold;
  min-width: 140px;
  text-align: center;
  white-space: nowrap;
  border-radius: 3px;
  padding: 11px 20px 12px 20px;
  text-transform: uppercase; }
  .my_btn.with_arrow {
    min-width: 200px;
    display: inline-flex;
    justify-content: space-around;
    justify-content: space-evenly;
    align-items: center; }
    .my_btn.with_arrow svg {
      display: block;
      width: 18px;
      height: 15px;
      margin-left: 7px; }
    .my_btn.with_arrow--left svg {
      transform: rotate(180deg); }

.btn_form {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 40px;
  padding: 0 20px;
  font-size: 10px;
  min-width: 160px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  letter-spacing: .1em;
  cursor: pointer;
  border: none;
  border-radius: 3px;
  background: #878787;
  border: 1px solid #878787;
  transition: background 0.225s cubic-bezier(0.39, 0.575, 0.565, 1);
  box-sizing: border-box; }
  .btn_form:hover, .btn_form:focus, .btn_form:active {
    background: #a1a1a1;
    border: 1px solid #a1a1a1; }

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 20px; }

.btn_block {
  display: block;
  text-align: center;
  width: 100%; }

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -7.5px; }
  .breadcrumbs_cover {
    margin-bottom: 20px; }
  .breadcrumbs .crumb {
    display: block;
    color: #000;
    font-size: 10px;
    padding: 5px 7.5px; }
    .breadcrumbs .crumb.js-lastcrumb {
      pointer-events: none; }
  .breadcrumbs .crumb-li {
    position: relative; }
  .breadcrumbs .crumb-li + .crumb-li::after {
    content: '';
    position: absolute;
    top: 6px;
    left: 0;
    bottom: 6px;
    width: 1px;
    background: #000; }

.pagination_cover .pagination {
  margin: 0 -2.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 30px; }
  .pagination_cover .pagination li {
    padding: 0 2.5px;
    margin-bottom: 5px; }
    .pagination_cover .pagination li:first-child span, .pagination_cover .pagination li:first-child a, .pagination_cover .pagination li:last-child span, .pagination_cover .pagination li:last-child a {
      font-size: 20px; }
    .pagination_cover .pagination li span, .pagination_cover .pagination li a {
      font-size: 12px;
      color: #000;
      display: block;
      line-height: 1;
      border: 1px solid #000;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      background: transparent; }
    .pagination_cover .pagination li.active span, .pagination_cover .pagination li.active a {
      background: #000;
      color: #fff; }
    .pagination_cover .pagination li.disabled span, .pagination_cover .pagination li.disabled a {
      opacity: 0.5;
      background: transparent !important;
      pointer-events: none; }
    .pagination_cover .pagination li:hover span, .pagination_cover .pagination li:hover a, .pagination_cover .pagination li:focus span, .pagination_cover .pagination li:focus a, .pagination_cover .pagination li:active span, .pagination_cover .pagination li:active a {
      background: #000;
      color: #fff; }

.video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0; }
  .video video,
  .video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.share_block {
  max-width: 100px;
  margin: 0 auto; }
  .share_block__cover {
    height: 100%; }
  .share_block__title {
    font-size: 12px;
    margin-bottom: 10px; }
  .share_block__block {
    display: flex;
    align-items: center;
    margin: 0 -10px;
    justify-content: flex-start; }
  .share_block__item {
    padding: 0 10px;
    width: 40px;
    height: 20px; }
  .share_block__link {
    display: block;
    height: 100%;
    width: 100%; }
    .share_block__link svg {
      fill: #000; }
    .share_block__link:hover svg, .share_block__link:focus svg, .share_block__link:active svg {
      fill: rgba(0, 0, 0, 0.8); }

.mistake_cover {
  background: #535353;
  position: fixed;
  z-index: 2;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow-y: auto;
  padding: 100px 0; }
  .mistake_cover .mistake_title {
    margin-bottom: calc(24px + 4 * ((100vw - 375px)/ 1650));
    font-size: calc(23px + 34 * ((100vw - 375px)/ 1650));
    line-height: calc(26px + 24 * ((100vw - 375px)/ 1650));
    text-transform: uppercase; }
  .mistake_cover .mistake_subtitle {
    font-size: calc(14px + 4 * ((100vw - 375px)/ 1650));
    line-height: calc(28px + 6 * ((100vw - 375px)/ 1650)); }
  .mistake_cover .mistake_block {
    max-width: calc(100% * 8/12 - (20px - 20px * 8/12));
    padding: 0  10px;
    margin: 0 auto; }
  .mistake_cover .mistake_link {
    margin-top: 50px; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center; }
  .modal.js_active {
    opacity: 1;
    visibility: visible; }
  .modal__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer; }
  .modal__holder {
    position: relative;
    max-height: 90vh;
    overflow-y: auto;
    width: 100%;
    background: #fff;
    box-shadow: 0 10px 30px rgba(203, 203, 203, 0.45);
    padding: 45px; }
    .modal__holder--standart {
      max-width: 490px; }
  .modal__close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    width: 40px;
    height: 40px;
    border: none;
    margin: 0;
    outline: none;
    box-shadow: none;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .modal__close_span {
      display: block;
      width: 18px;
      height: 18px; }
    .modal__close svg {
      fill: rgba(0, 0, 0, 0.5); }
    .modal__close:hover svg, .modal__close:focus svg, .modal__close:active svg {
      fill: #000; }
  .modal__header {
    font-family: "optimaregular", sans-serif;
    color: #000;
    font-size: 24px;
    margin: 0 0 30px;
    text-align: center; }
  .modal__footer {
    padding-top: 35px; }
  .modal .input__wrapper + .input__wrapper {
    margin-top: 20px; }
  .modal .form__success {
    padding-top: 20px; }

@media (max-width: 767px) {
  .modal__holder {
    padding: 30px 15px; }
  .modal__header {
    font-size: 20px;
    margin: 0 0 15px; }
  .modal__close {
    top: 0;
    right: 0; }
  .modal__footer {
    padding-top: 15px; }
    .modal__footer .block__auto {
      width: 100%;
      text-align: center; }
      .modal__footer .block__auto .butn {
        width: 240px; } }

.slick-dots li button {
  width: 20px;
  height: 20px;
  position: relative; }
  .slick-dots li button:before, .slick-dots li button:after {
    content: '';
    border: 1px solid #000;
    border-radius: 10px;
    transition: width .3s,height .3s;
    width: 4px;
    height: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    background: transparent;
    transform: translate(-50%, -50%); }
  .slick-dots li button:hover::before, .slick-dots li button:focus::before, .slick-dots li button:active::before {
    width: 10px;
    height: 10px; }

.slick-dots li.slick-active button::before {
  width: 10px;
  height: 10px; }

.slick-arrow {
  position: absolute;
  width: 40px;
  top: 20px;
  bottom: 0;
  height: auto;
  transform: translate(0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }
  .slick-arrow::before {
    content: none;
    font-family: "optimaregular", sans-serif; }
  .slick-arrow svg {
    display: block;
    width: 30px;
    height: 30px;
    fill: rgba(0, 0, 0, 0.5); }
  .slick-arrow.slick_arrow_prev {
    left: 0; }
  .slick-arrow.slick_arrow_next {
    right: 0; }

.form__title {
  font-size: 25px;
  text-align: center;
  text-transform: uppercase;
  line-height: calc(30 / 25);
  margin-bottom: 15px; }

.form__block {
  padding-top: 25px;
  margin-bottom: 25px;
  width: 100%;
  border-bottom: 1px solid rgba(204, 204, 204, 0.2); }

.form__flex {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -30px;
  margin-bottom: 45px; }

.form__item {
  padding: 0 30px; }
  .form__item--25 {
    width: 25%; }
  .form__item--33 {
    width: 33.3%; }
  .form__item--50 {
    width: 50%; }
  .form__item--100 {
    width: 100%; }

.form__btn {
  text-align: center; }

.form__success {
  color: #ae8057;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  padding-top: 45px;
  line-height: 1.2; }

.input__wrapper {
  position: relative; }
  .input__wrapper.forms__error .input__block {
    border: 1px solid #ae8057; }

.input__error {
  position: absolute;
  color: #ae8057;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 10px;
  left: 0;
  bottom: -15px;
  pointer-events: none; }

.input__block {
  border: 1px solid #ccc;
  width: 100%; }

.input__input {
  display: block;
  width: 100%;
  height: 36px;
  padding: 7px 15px;
  margin: 0;
  font-size: 12px;
  line-height: 1;
  color: #000;
  letter-spacing: 1px;
  cursor: pointer;
  border: none;
  border-radius: 0;
  background: 0 0;
  font-family: "optimaregular", sans-serif; }
  .input__input.textarea {
    height: 100px;
    padding-top: 15px; }
  .input__input:focus {
    border-bottom: 2px solid rgba(204, 204, 204, 0.7); }

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #000;
  text-transform: uppercase; }

::-moz-placeholder {
  /* Firefox 19+ */
  color: #000;
  text-transform: uppercase; }

:-ms-input-placeholder {
  /* IE 10+ */
  color: #000;
  text-transform: uppercase; }

:-moz-placeholder {
  /* Firefox 18- */
  color: #000;
  text-transform: uppercase; }

.selectric {
  background: #fff;
  border: 0 solid #ccc; }
  .selectric .button {
    background: #fff;
    color: #ccc; }
    .selectric .button:after {
      top: -5px;
      border: none;
      border-top: 1px solid #ae8057;
      border-left: 1px solid #ae8057;
      width: 12px;
      height: 12px;
      -webkit-transform: rotate(-135deg);
      transform: rotate(-135deg); }
  .selectric .label {
    text-transform: uppercase;
    height: 36px;
    font-size: 12px;
    color: #000; }

.selectric-open .selectric {
  border-bottom: 1px solid #ccc; }

.selectric-items {
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: none; }

.selectric-items li.select_placeholder {
  display: none; }

.selectric-items ul, .selectric-items li {
  font-size: 16px;
  color: #000; }

.selectric-items li.highlighted,
.selectric-items li.selected,
.selectric-items li:hover,
.selectric-items li:focus,
.selectric-items li:active {
  color: #fff;
  background: #ae8057; }

.selectric-items li.select_placeholder {
  display: none; }

.forms__error .selectric {
  border: none; }

.input__hidden {
  display: none; }

.checkbox__cover + .checkbox__cover {
  margin: 5px 0 0; }

.checkbox__label {
  position: relative;
  display: inline-block;
  line-height: 40px;
  vertical-align: text-top;
  font-size: 10px;
  text-transform: uppercase;
  font-family: "optimaregular", sans-serif;
  padding-left: 55px;
  color: #000;
  cursor: pointer; }
  .checkbox__label span, .checkbox__label a {
    line-height: 40px;
    vertical-align: middle;
    color: #000; }
  .checkbox__label a {
    text-decoration-color: #000;
    text-decoration-line: underline; }
  .checkbox__label::before {
    content: "";
    display: block;
    width: 40px;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 2px;
    background: #fff;
    position: absolute;
    left: 0;
    top: 0; }
  .checkbox__label::after {
    content: none;
    display: block;
    width: 40px;
    height: 40px;
    background: transparent;
    border-radius: 2px;
    position: absolute;
    left: 0;
    top: 0; }

.checkbox__checked {
  position: absolute;
  top: 7px;
  left: 15px;
  width: 10px;
  height: 17px;
  background-color: transparent;
  border-right: 1px solid #ae8057;
  border-bottom: 1px solid #ae8057;
  z-index: 1;
  opacity: 0;
  transform: rotate(45deg); }

.checkbox__text--red {
  color: #ae8057; }

.checkbox__text.js_hide {
  display: none; }

.checkbox__input:checked + .checkbox__label::after {
  content: ''; }

.checkbox__input:checked + .checkbox__label .checkbox__checked {
  opacity: 1; }

.cat_unit__link {
  display: flex;
  flex-direction: column;
  color: #000; }
  .cat_unit__link:hover .cat_unit__img, .cat_unit__link:focus .cat_unit__img, .cat_unit__link:active .cat_unit__img {
    box-shadow: 5px 5px 15px 4px rgba(0, 0, 0, 0.2); }
  .cat_unit__link:hover .cat_unit__title, .cat_unit__link:focus .cat_unit__title, .cat_unit__link:active .cat_unit__title {
    border-bottom: 2px solid rgba(0, 0, 0, 0.2); }

.cat_unit__img {
  display: block; }

.cat_unit__title {
  border-bottom: 2px solid transparent;
  align-self: flex-start;
  padding-top: 15px;
  font-size: calc(18px + 13 * ((100vw - 375px)/ 1650));
  line-height: calc(20px + 13 * ((100vw - 375px)/ 1650)); }

.text__admin {
  font-size: 14px;
  line-height: 1.4;
  font-family: "optimaregular", sans-serif;
  color: #000; }
  .text__admin h1, .text__admin h2, .text__admin h3, .text__admin h4, .text__admin h5, .text__admin h6 {
    line-height: normal;
    font-family: "optimaregular", sans-serif;
    color: #000;
    font-weight: 400;
    margin: 25px 0 15px; }
  .text__admin h1 {
    font-size: 26px; }
  .text__admin h2 {
    font-size: 24px; }
  .text__admin h3 {
    font-size: 22px; }
  .text__admin h4, .text__admin h5, .text__admin h6 {
    font-size: 20px; }
  .text__admin p + p {
    margin: 15px 0 0; }
  .text__admin p + ul,
  .text__admin p + ol {
    margin: 15px 0 0; }
  .text__admin ul + p,
  .text__admin ol + p {
    margin: 15px 0 0; }
  .text__admin p {
    margin: 0; }
    .text__admin p a {
      display: inline;
      color: #000;
      text-decoration: underline; }
    .text__admin p img, .text__admin p iframe {
      display: block;
      max-width: 100%;
      height: auto !important;
      margin: 0 auto; }
  .text__admin ul, .text__admin ol {
    list-style-position: inside; }
    .text__admin ul li, .text__admin ol li {
      font-size: calc(14px + 4 * ((100vw - 375px)/ 1650));
      line-height: calc(28px + 6 * ((100vw - 375px)/ 1650)); }
      .text__admin ul li a, .text__admin ol li a {
        display: inline;
        color: #000;
        text-decoration: underline; }
      .text__admin ul li img, .text__admin ul li iframe, .text__admin ol li img, .text__admin ol li iframe {
        display: block;
        max-width: 100%;
        height: auto !important;
        margin: 0 auto; }
    .text__admin ul li + li, .text__admin ol li + li {
      margin-top: 5px; }
  .text__admin ul {
    list-style-type: disc; }
  .text__admin ol {
    list-style-type: decimal; }
  .text__admin.big {
    font-size: calc(14px + 4 * ((100vw - 375px)/ 1650));
    line-height: calc(28px + 6 * ((100vw - 375px)/ 1650)); }

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  transition: all .25s linear .15s;
  background: transparent; }
  .header.header__page {
    position: absolute; }
  .header__cover {
    width: 100%;
    margin: 0 auto; }
  .header__container {
    position: relative;
    display: flex;
    height: 65px;
    padding: 0 40px;
    width: 100%;
    background: transparent;
    color: #fff;
    align-items: center; }
  .header__logo {
    position: absolute;
    width: 169px;
    height: 18px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1; }
    .header__logo_inner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 169px; }
    .header__logo svg {
      fill: #fff; }
  .header__item {
    width: 50%; }
    .header__item--adaptive {
      display: none; }
  .header__left {
    text-align: left; }
  .header__right {
    text-align: right; }
    .header__right .header__icon svg {
      transform: rotate(180deg); }
  .header__icon {
    display: inline-block;
    width: 70px;
    height: 16px;
    cursor: pointer;
    opacity: 1;
    transition: opacity .4s linear; }

.menu {
  display: flex;
  height: 100%; }
  .menu__cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 40px; }
  .menu__item {
    width: 50%;
    display: flex; }
  .menu__left {
    justify-content: flex-start; }
  .menu__right {
    justify-content: flex-end; }
  .menu__ul {
    display: inline-flex;
    align-items: center;
    margin: 0 -8px;
    transition: 0.4s cubic-bezier(0.7, 0, 1, 0.3), opacity 0.4s linear, color;
    opacity: 0; }
    .menu__ul--left {
      justify-content: flex-start;
      transform: translateX(-60px); }
    .menu__ul--right {
      justify-content: flex-end;
      transform: translateX(60px); }
  .menu__li {
    padding: 0 8px; }
    .menu__li--adaptive {
      display: none; }
  .menu__link {
    text-transform: uppercase;
    white-space: nowrap;
    pointer-events: all;
    font-size: 15px;
    position: relative;
    display: inline-block;
    padding: 10px 4px;
    text-decoration: none;
    color: #fff;
    letter-spacing: .06em;
    cursor: pointer;
    transition: color .2s,opacity .3s; }
    .menu__link:before {
      content: '';
      position: absolute;
      display: block;
      width: 24px;
      height: 24px;
      top: 50%;
      left: calc(50% - 12px);
      opacity: 0;
      background-repeat: no-repeat;
      background-size: contain;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      transition: opacity .3s;
      background-image: url(../images/cancel.svg);
      background-repeat: no-repeat;
      background-color: inherit; }
    .menu__link:after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      left: -1px;
      margin-top: 2px;
      opacity: 0;
      transition: opacity .2s .2s;
      border-bottom: 2px solid rgba(255, 255, 255, 0.4); }
    .menu__link:hover:after, .menu__link:focus:after, .menu__link:active:after {
      opacity: 1; }
    .menu__link.js_active {
      color: rgba(255, 255, 255, 0.2); }
      .menu__link.js_active svg {
        opacity: .2; }
      .menu__link.js_active:before {
        opacity: 1;
        transition: opacity .2s .2s; }
  .menu__search svg {
    width: 18px;
    height: 17px; }

.menu_li--adaptive {
  display: none; }

.menu__search_text {
  display: none; }

.submenu__li--back {
  display: none; }

.header:hover .header__icon, .header:focus .header__icon, .header:active .header__icon, .header.js_active .header__icon {
  opacity: 0;
  transition: opacity .3s linear .2s; }

.header:hover .menu__ul, .header:focus .menu__ul, .header:active .menu__ul, .header.js_active .menu__ul {
  opacity: 1;
  transform: translateX(0);
  transition: transform 0.5s cubic-bezier(0, 0.7, 0.3, 1), opacity 0.3s linear 0.2s, -webkit-transform 0.5s cubic-bezier(0, 0.7, 0.3, 1); }

.header.js_active_left {
  background: #161718;
  transition: all .25s linear .15s; }
  .header.js_active_left .menu__ul--left {
    opacity: 1;
    transform: translateX(0);
    transition: transform 0.5s cubic-bezier(0, 0.7, 0.3, 1), opacity 0.3s linear 0.2s, -webkit-transform 0.5s cubic-bezier(0, 0.7, 0.3, 1); }
  .header.js_active_left .header__icon--left {
    opacity: 0;
    transition: opacity .3s linear .2s; }

.header.js_active_right {
  background: #161718;
  transition: all .25s linear .15s; }
  .header.js_active_right .menu__ul--right {
    opacity: 1;
    transform: translateX(0);
    transition: transform 0.5s cubic-bezier(0, 0.7, 0.3, 1), opacity 0.3s linear 0.2s, -webkit-transform 0.5s cubic-bezier(0, 0.7, 0.3, 1); }
  .header.js_active_right .header__icon--right {
    opacity: 0;
    transition: opacity .3s linear .2s; }

.submenu__search {
  max-width: 1655px;
  padding-top: 80px;
  margin: 0 auto; }

.submenu__cover {
  position: fixed;
  z-index: -1;
  top: 65px;
  left: -40px;
  width: 101vw;
  height: 100vh;
  overflow-y: auto;
  padding: 55px 60px 80px;
  background: #161718;
  color: #fff;
  transition: transform 0.5s cubic-bezier(0.8, 0, 0.2, 1), opacity 0.3s linear, -webkit-transform 0.5s cubic-bezier(0.8, 0, 0.2, 1);
  opacity: 0;
  pointer-events: none;
  display: none; }
  .submenu__cover--right {
    left: auto;
    right: -40px; }
  .submenu__cover.js_active {
    transition: transform 0.5s cubic-bezier(0, 0.7, 0.3, 1), opacity 0.25s linear 0.15s, -webkit-transform 0.5s cubic-bezier(0, 0.7, 0.3, 1);
    opacity: 1;
    pointer-events: all;
    display: block; }

.submenu__ul {
  padding-left: 45px;
  width: 100%;
  position: relative; }

.submenu__li + .submenu__li {
  margin-top: 4.5vh; }

.submenu__link {
  font-size: 5vh;
  display: inline-block;
  line-height: 1em;
  text-transform: uppercase;
  color: #fff;
  opacity: 0.6;
  transition: opacity .25s linear; }
  .submenu__link:hover, .submenu__link:focus, .submenu__link:active, .submenu__link.js_active {
    opacity: 1; }
  .submenu__link--3 {
    font-size: 3vh; }

.submenu__progress {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 140px;
  transition: opacity .25s linear; }
  .submenu__progress.js_active {
    opacity: 1; }

.progress {
  position: relative;
  top: -5px; }
  .progress__item + .progress__item {
    margin-top: 20px; }
  .progress__item {
    display: flex; }
  .progress__name {
    font-size: 11px;
    text-transform: uppercase;
    line-height: 2.18em;
    letter-spacing: .083em;
    color: #fff; }
  .progress__progress {
    display: block;
    width: 194px;
    height: 2px;
    color: #fff;
    border: none;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.2);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }
  .progress__value {
    position: relative;
    display: flex;
    flex-direction: row;
    align-self: center;
    margin-left: 18px;
    font-size: 24px;
    min-width: 110px; }
  .progress__type {
    margin-left: 7px;
    opacity: .5; }
  .progress__notvalue {
    font-size: 12px;
    line-height: 32px;
    letter-spacing: .15em; }

progress[value]::-webkit-progress-value,
progress[value]::-moz-progress-value {
  background: #fff; }

progress {
  color: #fff; }

progress::-webkit-progress-value {
  background: #fff; }

progress::-moz-progress-bar {
  background: #fff; }

.search__form {
  position: relative; }
  .search__form ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #fff;
    text-transform: uppercase; }
  .search__form ::-moz-placeholder {
    /* Firefox 19+ */
    color: #fff;
    text-transform: uppercase; }
  .search__form :-ms-input-placeholder {
    /* IE 10+ */
    color: #fff;
    text-transform: uppercase; }
  .search__form :-moz-placeholder {
    /* Firefox 18- */
    color: #fff;
    text-transform: uppercase; }

.search__input {
  display: block;
  width: 100%;
  height: auto;
  padding: .5em 50px .22em 0;
  margin-bottom: .28em;
  margin-left: 0;
  font-size: 46px;
  text-transform: none;
  color: #fff;
  border: none;
  border-radius: none;
  background: 0 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-bottom: 2px solid #fff; }
  .search__input:hover, .search__input:focus, .search__input:active {
    border-bottom: 2px solid #fff; }

.search__btn {
  position: absolute;
  display: block;
  width: 34px;
  height: 36px;
  bottom: 20px;
  right: 0;
  background: transparent;
  padding: 0; }
  .search__btn svg {
    fill: #fff; }

@media (max-width: 1199px) {
  .header__item {
    display: none; }
    .header__item--adaptive {
      display: block;
      margin-left: auto;
      position: relative;
      z-index: 5; }
      .header__item--adaptive .header__icon {
        opacity: 1;
        height: 30px;
        position: relative; }
        .header__item--adaptive .header__icon:before {
          content: '';
          position: absolute;
          display: block;
          width: 17px;
          height: 17px;
          top: 50%;
          right: 0;
          opacity: 0;
          background-repeat: no-repeat;
          background-size: contain;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          transition: opacity .3s;
          background-image: url(../images/cancel.svg);
          background-repeat: no-repeat;
          background-color: inherit; }
        .header__item--adaptive .header__icon.js_active svg {
          opacity: 0; }
        .header__item--adaptive .header__icon.js_active:before {
          opacity: 1; }
  .header:hover .header__icon, .header:focus .header__icon, .header:active .header__icon, .header.js_active .header__icon {
    opacity: 1;
    transition: opacity .3s linear .2s; }
  .header.js_active_right, .header.js_active_left {
    background: transparent; }
  .menu {
    display: block;
    height: 100%;
    padding: 0 20px;
    overflow-y: auto; }
    .menu__cover {
      position: fixed;
      background: #161718;
      padding: 75px 0 50px;
      opacity: 0;
      pointer-events: none;
      display: none; }
      .menu__cover.js_active {
        pointer-events: all;
        opacity: 1;
        display: block; }
    .menu__right {
      justify-content: flex-start; }
    .menu__item {
      width: 100%; }
    .menu__ul {
      flex-direction: column;
      align-content: flex-start;
      opacity: 1;
      transform: translateX(0);
      width: 100%; }
      .menu__ul--left, .menu__ul--right {
        transform: translateX(0);
        justify-content: flex-start;
        align-items: flex-start; }
    .menu__link {
      font-size: 30px;
      letter-spacing: .4px;
      text-transform: none;
      padding: 15px 0;
      white-space: normal; }
      .menu__link.js_active {
        color: #fff; }
        .menu__link.js_active svg {
          opacity: 1; }
        .menu__link.js_active:before {
          opacity: 0;
          transition: opacity .2s .2s; }
      .menu__link.js_hidden {
        font-size: 0;
        padding: 0;
        height: 0; }
    .menu:hover:after, .menu:active:after, .menu:focus:after {
      opacity: 0; }
    .menu__li--search {
      width: 100%; }
    .menu__li--adaptive {
      display: block; }
    .menu__search {
      width: 100%;
      display: flex;
      align-items: baseline;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      justify-content: space-between;
      padding-bottom: 5px; }
  .menu__search_text {
    display: inline-block; }
  .submenu__cover {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px 0 0; }
    .submenu__cover.js_active {
      display: block;
      z-index: 1; }
  .submenu__ul {
    padding-left: 0; }
  .submenu__link {
    opacity: 1; }
  .submenu__progress {
    display: none; }
  .menu_li--adaptive {
    display: block; }
  .menu__item.menu__left {
    display: none; }
  .menu__item.menu__right {
    height: 100%; }
  .submenu__li--back {
    display: inline-flex;
    align-items: center;
    margin-bottom: 50px;
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer; }
    .submenu__li--back .back_text {
      padding-left: 10px; }
    .submenu__li--back svg {
      width: 20px;
      height: 20px; }
  .search__input {
    font-size: 30px; }
  .search__btn {
    width: 25px;
    height: 25px;
    bottom: 10px; } }

@media (max-width: 767px) {
  .header__container {
    padding: 0 15px; } }

.footer {
  background-color: #262626;
  background-image: repeating-linear-gradient(110deg, #2f2f2f, #2f2f2f 1px, transparent 1px, transparent 6px, #2f2f2f 6px, #2f2f2f 7px, transparent 7px, transparent 12px, #2f2f2f 12px, #2f2f2f 13px, transparent 13px, transparent 18px, #2f2f2f 18px, #2f2f2f 19px, transparent 19px, transparent 24px, #2f2f2f 24px, #2f2f2f 25px, transparent 25px, transparent 30px, #2f2f2f 30px, #2f2f2f 31px, transparent 31px, transparent 45px), repeating-linear-gradient(-110deg, #2f2f2f, #2f2f2f 1px, transparent 1px, transparent 6px, #2f2f2f 6px, #2f2f2f 7px, transparent 7px, transparent 12px, #2f2f2f 12px, #2f2f2f 13px, transparent 13px, transparent 18px, #2f2f2f 18px, #2f2f2f 19px, transparent 19px, transparent 24px, #2f2f2f 24px, #2f2f2f 25px, transparent 25px, transparent 30px, #2f2f2f 30px, #2f2f2f 31px, transparent 31px, transparent 45px);
  padding: 40px 0; }
  .footer__container {
    width: 100%;
    max-width: 100%;
    padding: 0 calc(99.9% / 12 - (20px - 20px / 12)); }
  .footer .header_lang {
    display: flex;
    align-items: center;
    justify-content: center; }
    .footer .header_lang__li + .footer .header_lang__li {
      position: relative; }
      .footer .header_lang__li + .footer .header_lang__li::after {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        border-left: 1px solid #fff; }
    .footer .header_lang__link {
      font-size: 12px;
      display: block;
      padding: 12px 5px 8px;
      text-transform: uppercase;
      color: #fff;
      opacity: 0.7;
      position: relative; }
      .footer .header_lang__link.active_link {
        font-family: "optimaregular", sans-serif;
        opacity: 1; }
  .footer__copyright {
    text-align: center;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
    text-transform: uppercase;
    letter-spacing: 1px; }
  .footer__top {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding-bottom: 25px;
    margin-bottom: 25px;
    position: relative; }
  .footer__menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 -5px; }
    .footer__menu_item {
      display: flex;
      align-items: center;
      padding: 0 5px;
      width: 42%; }
    .footer__menu_center {
      justify-content: center;
      width: 16%; }
    .footer__menu_left {
      justify-content: flex-start;
      margin: 0 -5px; }
      .footer__menu_left .footer__link {
        text-align: left; }
    .footer__menu_right {
      justify-content: flex-end;
      margin: 0 -5px; }
      .footer__menu_right .footer__li {
        text-align: right; }
      .footer__menu_right .footer__link {
        text-align: right; }
  .footer__li {
    padding: 0 5px; }
  .footer__link {
    width: 100%;
    color: rgba(255, 255, 255, 0.5);
    position: relative;
    display: inline-block;
    padding: 12px 10px 8px;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: color 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95); }
    .footer__link:hover, .footer__link:focus, .footer__link:active {
      color: white; }
  .footer__logo {
    width: 148px;
    height: 64px;
    position: relative;
    overflow: hidden; }
    .footer__logo_inner {
      display: block;
      height: 0;
      padding: 0 0 100%;
      overflow: hidden;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      .footer__logo_inner svg {
        fill: #fff;
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%; }

@media (min-width: 1900px) {
  .footer__top {
    padding-left: 50px;
    padding-right: 50px; }
  .footer__li {
    padding: 0 20px; }
  .footer__menu_left, .footer__menu_right {
    margin: 0 -20px; } }

@media (max-width: 1439px) {
  .footer__menu {
    justify-content: center;
    flex-wrap: wrap; }
    .footer__menu_item {
      width: auto; }
    .footer__menu_center {
      width: 100%;
      order: -1;
      display: flex;
      margin-bottom: 20px; } }

@media (max-width: 1023px) {
  .footer__container {
    padding: 0 calc(99.9% / 12 - (10px - 10px / 12)); }
  .footer__menu {
    flex-direction: column;
    align-items: center; }
    .footer__menu_item {
      display: block;
      width: 100%; }
    .footer__menu_center {
      order: -1;
      display: flex;
      margin-bottom: 20px; }
    .footer__menu_left .footer__link, .footer__menu_right .footer__link {
      text-align: center; } }

.main_slider__section.active .main_slider__text {
  opacity: 1;
  transform: translateY(0); }

.main_slider__section.active .main_slider__title {
  opacity: 1;
  transform: translateY(0); }

.main_slider__section.active .main_slider__btn {
  opacity: 1;
  transform: translateY(0); }

.main_slider__block {
  padding: 0 calc(99.9% / 12 - (20px - 20px / 12));
  position: relative;
  overflow: hidden;
  height: 100%;
  max-height: 100vh;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center; }

.main_slider__video {
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-height: 100vh; }

.main_slider__info {
  width: 100%;
  max-width: 1625px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center; }

.main_slider__link {
  display: block;
  width: 100%;
  max-width: 800px;
  text-align: center;
  padding: 40px;
  color: #fff;
  position: relative;
  z-index: 2; }

.main_slider__text {
  margin-bottom: 10px;
  letter-spacing: 2px;
  font-size: calc(12px + 4 * ((100vw - 375px)/ 1650));
  color: #fff;
  text-transform: uppercase;
  transform: translateY(60px);
  opacity: 0;
  transition: opacity 1s linear 0.4s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s; }
  .main_slider__text p {
    margin: 0; }

.main_slider__title {
  line-height: 1;
  font-size: calc(35px + 30 * ((100vw - 375px)/ 1650));
  margin-bottom: 20px;
  color: #fff;
  text-transform: uppercase;
  transform: translateY(60px);
  opacity: 0;
  transition: opacity 1s linear 0.625s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.625s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.625s; }

.main_slider__btn {
  line-height: 1;
  height: 40px;
  padding: 0 20px;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  cursor: pointer;
  font-size: 10px;
  width: auto;
  text-align: center;
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.2);
  min-width: 160px;
  transform: translateY(60px);
  opacity: 0;
  transition: opacity 1s linear 0.85s, transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.85s, -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.85s; }

.main_slider__preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  color: #fff;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: block;
  width: 32px;
  height: 32px;
  z-index: 0; }

.main_slider__loader {
  position: relative;
  display: block;
  -webkit-animation-name: loader-animation;
  animation-name: loader-animation;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  animation-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: forward;
  animation-direction: forward;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  width: 100%;
  height: 100%; }
  .main_slider__loader svg {
    fill: #fff; }

.main_slider__poster {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

#fp-nav li:last-child,
.fp-slidesNav li:last-child {
  display: none;
  opacity: 0; }

#fp-nav a,
.fp-slidesNav a {
  width: 20px;
  height: 20px;
  position: relative; }
  #fp-nav a::after, #fp-nav a::before,
  .fp-slidesNav a::after,
  .fp-slidesNav a::before {
    content: '';
    border: 1px solid #fff;
    border-radius: 10px;
    transition: width .3s,height .3s;
    width: 4px;
    height: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  #fp-nav a:hover::before, #fp-nav a:focus::before, #fp-nav a:active::before, #fp-nav a.active::before,
  .fp-slidesNav a:hover::before,
  .fp-slidesNav a:focus::before,
  .fp-slidesNav a:active::before,
  .fp-slidesNav a.active::before {
    width: 10px;
    height: 10px; }

#fp-nav span,
.fp-slidesNav span {
  display: none; }

#fp-nav {
  z-index: 50; }

.news__top {
  position: relative;
  display: block;
  height: 0;
  padding: 0 0 24%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.4); }
  .news__top_img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center; }
    .news__top_img .img_responsive {
      display: block;
      margin: 0;
      -o-object-fit: cover;
      object-fit: cover;
      width: 100%;
      height: 100%; }
    .news__top_img:after {
      content: '';
      z-index: 2;
      background: rgba(0, 0, 0, 0.4);
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      position: absolute; }
  .news__top_text {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center; }

.news__cover {
  padding-top: 4.625vw;
  padding-bottom: 4.625vw; }

.news__preinfo {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  font-size: 14px;
  margin-bottom: 30px; }

.news__postinfo {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  font-size: 14px;
  margin-top: 30px; }

.news__item {
  font-size: calc(14px + 4 * ((100vw - 375px)/ 1650));
  line-height: calc(28px + 6 * ((100vw - 375px)/ 1650));
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  border-bottom: 1px solid #ccc; }
  .news__item_date {
    font-size: calc(12px + 4 * ((100vw - 375px)/ 1650));
    line-height: 1.75;
    font-style: italic;
    display: block; }
  .news__item_link {
    font-size: 15px;
    line-height: 2em;
    text-transform: uppercase;
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    color: #000;
    font-family: "optimaregular", sans-serif; }
    .news__item_link:hover, .news__item_link:focus, .news__item_link:active {
      border-bottom: 2px solid rgba(0, 0, 0, 0.1); }
  .news__item_text {
    font-size: calc(12px + 4 * ((100vw - 375px)/ 1650));
    line-height: 1.75; }
    .news__item_text p {
      margin: 0; }

.new_item__title {
  margin-bottom: calc(28px + 34 * ((100vw - 375px)/ 1650));
  font-size: calc(18px + 8 * ((100vw - 375px)/ 1650));
  text-transform: uppercase;
  line-height: calc(22px + 6 * ((100vw - 375px)/ 1650)); }

.new_item__date {
  display: block;
  margin-bottom: 1em;
  font-size: calc(14px + 4 * ((100vw - 375px)/ 1650));
  line-height: calc(28px + 6 * ((100vw - 375px)/ 1650));
  font-style: italic; }

.new_item__img {
  margin-bottom: 3em; }

.new_item__video {
  margin-top: 3em; }

.new_item__gallery {
  margin: 0 -10px;
  margin-top: 3em; }
  .new_item__gallery .slick-slide > div {
    padding: 0 10px; }

.new_item__photo {
  display: block; }

.contacts__subtitle {
  margin-bottom: calc(26px + 14 * ((100vw - 375px)/ 1650));
  font-size: calc(24px + 10 * ((100vw - 420px)/ 860));
  line-height: calc(26px + 14 * ((100vw - 375px)/ 1650)); }

.contacts__block {
  margin-bottom: 3em; }

.contacts__map {
  position: relative;
  padding-bottom: 25%;
  height: 0; }
  .contacts__map iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.contacts__item {
  font-size: 16px;
  font-style: italic; }
  .contacts__item_title {
    text-transform: uppercase;
    font-size: 18px;
    margin: 0 0 30px; }
  .contacts__item_address {
    font-style: normal; }
  .contacts__item_block + .contacts__item_block {
    margin: 20px 0 0; }
  .contacts__item_phones {
    display: flex;
    flex-direction: column; }
  .contacts__item_timework {
    margin-top: 40px; }
    .contacts__item_timework p {
      margin: 0; }

.contacts__link + .contacts__link {
  margin: 7px 0 0; }

.contacts__link {
  color: #000; }
  .contacts__link.with_svg {
    display: flex;
    align-items: center; }
    .contacts__link.with_svg svg {
      display: block;
      fill: rgba(0, 0, 0, 0.4);
      width: 14px;
      height: 14px;
      margin-right: 7px; }
  .contacts__link:hover, .contacts__link:focus, .contacts__link:active {
    color: #000;
    text-decoration: underline; }

.contacts__form {
  max-width: 1000px;
  margin: 0 auto; }
  .contacts__form_cover {
    margin-top: 5em;
    padding-top: 5em;
    border-top: 1px solid #ccc; }

.contacts__admin {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 25px; }

.contacts__team_cover {
  margin-top: 5em;
  padding-top: 5em;
  border-top: 1px solid #ccc; }

.contacts__team .title {
  text-transform: uppercase; }

.contacts__team_gallery_desc {
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  max-width: 610px;
  margin: 0 auto;
  margin-bottom: calc(26px + 14 * ((100vw - 375px)/ 1650)); }

.contacts__team_paddind {
  padding: 0 10px; }

.contacts__team_item {
  height: 100%;
  border-left: 1px solid #ccc; }

.contacts__team_info {
  padding: 24px; }

.contacts__team_title {
  letter-spacing: .15625em;
  font-size: 16px;
  text-transform: uppercase; }

.contacts__team_desc {
  margin: 15px 0 0;
  font-size: 16px;
  display: flex;
  flex-direction: column; }
  .contacts__team_desc * + * {
    margin: 10px 0 0; }
  .contacts__team_desc .name {
    margin-bottom: 15px; }
  .contacts__team_desc a {
    color: #000;
    font-style: italic; }
    .contacts__team_desc a:hover, .contacts__team_desc a:focus, .contacts__team_desc a:active {
      color: #000;
      text-decoration: underline; }

.social__holder {
  margin: 15px 0 0; }

.social__flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -7.5px; }
  .social__flex.center {
    justify-content: center; }

.social__item {
  padding: 0 7.5px; }

.social__messenger {
  display: flex;
  align-items: center;
  justify-content: center; }
  .social__messenger .social__icon {
    display: block;
    width: 20px;
    height: 20px; }
    .social__messenger .social__icon svg {
      fill: rgba(0, 0, 0, 0.4); }
    .social__messenger .social__icon:hover svg, .social__messenger .social__icon:focus svg, .social__messenger .social__icon:active svg {
      fill: rgba(0, 0, 0, 0.8); }

.social__network {
  display: flex;
  align-items: center;
  justify-content: center; }
  .social__network .social__icon {
    display: block;
    width: 20px;
    height: 20px; }
    .social__network .social__icon svg {
      fill: rgba(0, 0, 0, 0.4); }
  .social__network:hover svg, .social__network:focus svg, .social__network:active svg {
    fill: rgba(0, 0, 0, 0.8); }

.stock_car__nav {
  padding: 20px 40px 0;
  overflow: hidden;
  visibility: hidden; }
  .stock_car__nav.slick-initialized {
    visibility: visible; }
  .stock_car__nav .slick-list {
    margin: 0 -10px; }
  .stock_car__nav .stock_car__nav_img {
    padding: 0 10px; }

.stock_car__info {
  color: #000; }

.stock_car__price {
  font-size: calc(18px + 13 * ((100vw - 375px)/ 1650));
  line-height: calc(20px + 13 * ((100vw - 375px)/ 1650));
  margin-bottom: 20px; }

.stock_car__btn {
  margin: 30px 0; }

.stock_car__bottom_chars .stock_car__chars_name {
  flex: 0 0 200px; }

.stock_car__chars {
  display: flex;
  margin: 0 -5px;
  font-size: 16px;
  line-height: 1.4; }
  .stock_car__chars_cover + .stock_car__chars_cover {
    margin: 5px 0 0; }
  .stock_car__chars_name {
    font-weight: 600;
    padding: 0 5px;
    flex: 0 0 120px; }
  .stock_car__chars_value {
    padding: 0 5px; }

.stock_car__prim {
  font-size: 12px;
  line-height: 1.4;
  margin-top: 40px;
  padding-top: 2vw; }

.stock_car__text {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #000;
  font-size: 16px;
  line-height: 1.4;
  font-family: "optimaregular", sans-serif; }
  .stock_car__text p {
    margin: 0; }
  .stock_car__text h1, .stock_car__text h2, .stock_car__text h3, .stock_car__text h4, .stock_car__text h5, .stock_car__text h6 {
    line-height: 1.2;
    font-family: "optimaregular", sans-serif;
    color: #000;
    margin: 10px 0 5px; }
  .stock_car__text h2 {
    font-size: 20px; }
  .stock_car__text h3 {
    font-size: 18px; }
  .stock_car__text h4, .stock_car__text h5, .stock_car__text h6 {
    font-size: 18px; }
  .stock_car__text p + p {
    margin: 5px 0 0; }
  .stock_car__text p + ul,
  .stock_car__text p + ol {
    margin: 5px 0 0; }
  .stock_car__text ul + p,
  .stock_car__text ol + p {
    margin: 5px 0 0; }
  .stock_car__text p {
    margin: 0; }
    .stock_car__text p a {
      display: inline;
      color: #000;
      text-decoration: underline; }
    .stock_car__text p img, .stock_car__text p iframe {
      display: block;
      max-width: 100%;
      height: auto !important;
      margin: 0 auto; }
  .stock_car__text ul, .stock_car__text ol {
    list-style-position: inside; }
    .stock_car__text ul li a, .stock_car__text ol li a {
      display: inline;
      color: #000;
      text-decoration: underline; }
    .stock_car__text ul li img, .stock_car__text ul li iframe, .stock_car__text ol li img, .stock_car__text ol li iframe {
      display: block;
      max-width: 100%;
      height: auto !important;
      margin: 0 auto; }
    .stock_car__text ul li + li, .stock_car__text ol li + li {
      margin-top: 5px; }
  .stock_car__text ul {
    list-style-type: disc; }
  .stock_car__text ol {
    list-style-type: decimal; }

@media (max-width: 1023px) {
  .stock_car__cover {
    width: 100%; }
  .stock_car__photo {
    max-width: 800px;
    margin: 0 auto; }
  .stock_car__cover + .stock_car__cover {
    margin-top: 30px; } }

@media (max-width: 575px) {
  .stock_car__price {
    font-size: 22px;
    line-height: 22px; }
  .stock_car__prim {
    margin-top: 20px; } }

@media (max-width: 1023px) {
  .new_item__sidebar {
    width: 100%;
    margin-top: 3em; }
  .new_item__cover {
    width: 100%; }
  .share_block {
    max-width: 100%; }
  .news__top {
    padding: 0 0 40%; }
  .form__flex {
    margin: 0 -7.5px;
    margin-bottom: 25px; }
  .form__item {
    padding: 0 7.5px; }
  .contacts__map {
    padding-bottom: 50%; }
  .video__cover {
    width: 100%;
    max-width: 800px;
    margin: 0 auto; }
  .contacts__team_item_cover {
    width: 50%; } }

@media (max-width: 767px) {
  .news__cover {
    padding-top: 30px;
    padding-bottom: 30px; }
  .news__top {
    padding: 0 0 55%; }
  .contacts__item_cover {
    width: 100%; }
  .contacts__item_cover + .contacts__item_cover {
    margin-top: 30px; }
  .contacts__item_timework {
    margin-top: 20px; }
  .contacts__item_title {
    margin: 0 0 20px; }
  .contacts__form_cover {
    margin-top: 3em;
    padding-top: 3em; }
  .form__title {
    font-size: 20px; }
  .form__item {
    width: 100%; }
  .form__item + .form__item {
    margin-top: 15px; }
  .form__block {
    padding-top: 10px;
    margin-bottom: 15px; }
  .cat_unit__cover {
    width: 100%; }
  .cat_unit__title {
    font-size: 18px;
    line-height: 1.3; }
  .contacts__team_item_cover {
    width: 100%;
    max-width: 550px; } }

@media (max-width: 575px) {
  .new_item__date {
    line-height: 28px;
    font-size: 14px; }
  .new_item__title {
    line-height: 22px;
    font-size: 18px; } }

@media (max-height: 450px) {
  .mistake_cover {
    align-items: flex-start; } }

.additions__section {
  color: #fff;
  background: #000; }

.additions__modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  z-index: 150;
  background: #000; }
  .additions__modal.js_active {
    opacity: 1;
    visibility: visible; }
  .additions__modal_overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer; }

.no-touchevents .additions_nav_section .additions_link::after {
  transition: width 0.6s cubic-bezier(0.19, 1, 0.22, 1); }

.no-touchevents .additions_nav_section .additions_link:hover, .no-touchevents .additions_nav_section .additions_link:focus, .no-touchevents .additions_nav_section .additions_link:active, .no-touchevents .additions_nav_section .additions_link.js_active {
  color: #fff; }
  .no-touchevents .additions_nav_section .additions_link:hover:after, .no-touchevents .additions_nav_section .additions_link:focus:after, .no-touchevents .additions_nav_section .additions_link:active:after, .no-touchevents .additions_nav_section .additions_link.js_active:after {
    width: calc(100% - 20px); }

.additions_nav_section {
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100vh - 65px);
  background: #262626;
  z-index: 10; }
  .additions_nav_section .additions_nav_scroll {
    top: -65px;
    overflow: hidden;
    transition: top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 0.6s;
    left: 0;
    right: 0;
    z-index: 100;
    background: #262626; }
    .additions_nav_section .additions_nav_scroll.js_fixed {
      position: fixed;
      overflow: visible;
      top: 0; }
  .additions_nav_section .additions_nav {
    width: 100%;
    max-width: calc(100% * 10/12 - (20px - 20px * 10/12));
    margin: 0 auto;
    padding: 0 20px;
    height: 65px; }
  .additions_nav_section .additions_ul {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    line-height: 1;
    width: 100%;
    height: 100%;
    margin: 0 -calc(20px/2); }
  .additions_nav_section .additions_li {
    padding: 0 calc(20px / 2); }
  .additions_nav_section .additions_link {
    position: relative;
    display: flex;
    padding: 20px calc(20px / 2);
    background: transparent;
    font-size: calc(9px + 5 * ((100vw - 769px)/ 1256));
    text-transform: uppercase;
    font-family: "optimaregular", sans-serif;
    color: rgba(255, 255, 255, 0.7);
    letter-spacing: 1px;
    transition: opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1); }
    .additions_nav_section .additions_link::after {
      content: '';
      width: 0;
      height: 2px;
      background: rgba(255, 255, 255, 0.2);
      bottom: 14px;
      left: 10px;
      position: absolute; }
    .additions_nav_section .additions_link.js_active {
      color: #fff; }
      .additions_nav_section .additions_link.js_active:after {
        width: calc(100% - 20px); }

@media (max-width: 1023px) {
  .additions_nav_section {
    top: calc(100vh - 55px); }
    .additions_nav_section .additions_nav {
      height: 55px; }
    .additions_nav_section .additions_ul {
      flex-direction: column;
      width: auto;
      height: auto; }
    .additions_nav_section .additions_link {
      display: none;
      opacity: 0;
      font-size: 13px;
      transition: opacity 0.8s cubic-bezier(0.39, 0.575, 0.565, 1); }
      .additions_nav_section .additions_link.js_active {
        display: flex;
        opacity: 1; } }

.main_image_with_title .additions__item {
  width: 100%;
  height: 100vh;
  min-height: 500px;
  position: relative; }

.main_image_with_title .additions__img {
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute; }
  .main_image_with_title .additions__img:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    z-index: 2;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%; }

.main_image_with_title .img_addit {
  display: block;
  margin: 0;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%; }

.main_image_with_title .additions__info {
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center; }

.main_image_with_title .additions__container {
  padding: 0 20px;
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 2; }

.main_image_with_title .additions__title {
  margin-bottom: calc(17px + 3 * ((100vw - 375px)/ 1650));
  font-size: calc(15px + 5 * ((100vw - 1200px)/ 825));
  line-height: 15px;
  letter-spacing: 2px;
  text-transform: uppercase; }

.main_image_with_title .additions__subtitle {
  font-size: calc(35px + 50 * ((100vw - 375px)/ 1650));
  line-height: calc(35px + 40 * ((100vw - 375px)/ 1650));
  text-transform: uppercase;
  max-width: 900px;
  margin: 0 auto; }

@media (min-width: 2025px) {
  .main_image_with_title .additions__title {
    font-size: 20px; }
  .main_image_with_title .additions__subtitle {
    line-height: 75px;
    font-size: 85px;
    max-width: calc(100% * 6/12 - (20px - 20px * 6/12)); } }

@media (max-width: 1199px) {
  .main_image_with_title .additions__title {
    font-size: 15px; } }

@media (max-width: 480px) {
  .main_image_with_title .additions__subtitle {
    line-height: 35px;
    font-size: 35px; } }

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7); }
  70% {
    -webkit-box-shadow: 0 0 0 5px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 5px rgba(255, 255, 255, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0); } }

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7); }
  70% {
    -moz-box-shadow: 0 0 0 5px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 5px rgba(255, 255, 255, 0); }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0); } }

.video_on_photo {
  padding-bottom: 50px; }
  .video_on_photo .additions__item {
    width: 100%;
    padding: 0 0 50%;
    position: relative; }
  .video_on_photo .additions__img {
    overflow: hidden;
    width: 100%;
    bottom: 16.66%;
    top: 0;
    left: 0;
    position: absolute; }
    .video_on_photo .additions__img:after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      z-index: 2;
      background: rgba(0, 0, 0, 0.3);
      width: 100%;
      height: 100%; }
  .video_on_photo .img_addit {
    display: block;
    margin: 0;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%; }
  .video_on_photo .additions__video_cover {
    position: absolute;
    height: 0;
    bottom: 0;
    padding-bottom: 31%;
    left: 0;
    width: 100%;
    z-index: 2; }
  .video_on_photo .additions__video_poster {
    top: 0;
    right: calc(99.9% / 12 - (20px - 20px / 12));
    left: calc(99.9% / 12 - (20px - 20px / 12));
    position: absolute;
    width: auto;
    height: 100%; }
  .video_on_photo .img_addit_poster {
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    -o-object-fit: cover;
    object-fit: cover;
    width: 99.9%;
    height: 100%; }
  .video_on_photo .additions__video {
    top: 0;
    right: calc(99.9% / 12 - (20px - 20px / 12));
    left: calc(99.9% / 12 - (20px - 20px / 12));
    position: absolute;
    width: auto;
    height: 100%; }
  .video_on_photo .video_addit {
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%; }
  .video_on_photo .additions__click {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    cursor: pointer; }
  .video_on_photo .additions__icon {
    display: block;
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 3.2vw;
    height: 3.2vw; }
    .video_on_photo .additions__icon svg {
      display: block;
      width: 100%;
      height: 100%;
      fill: #fff; }
  .video_on_photo .additions__icon_top {
    display: block;
    width: 36px;
    height: 36px;
    cursor: pointer;
    left: 50%;
    transform: translateX(-50%);
    bottom: calc(100% + 110px);
    position: absolute;
    z-index: 3;
    border-radius: 50%;
    animation: pulse 2s infinite; }
    .video_on_photo .additions__icon_top svg {
      fill: #fff; }

.modal_video_on_photo .additions__modal_holder {
  position: relative;
  padding: 0;
  min-height: 100%; }

.modal_video_on_photo .additions__modal_video {
  top: 65px;
  bottom: 65px;
  left: 0;
  position: absolute;
  width: 100%; }

.modal_video_on_photo .additions__modal_video_addit {
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%; }

.modal_video_on_photo .additions__modal_close {
  position: absolute;
  cursor: pointer;
  z-index: 3;
  top: 5px;
  left: auto;
  right: 5px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .modal_video_on_photo .additions__modal_close svg {
    fill: #fff;
    display: block;
    width: 16px;
    height: 16px; }

@media (max-width: 767px) {
  .video_on_photo .additions__item {
    height: 400px; }
  .video_on_photo .additions__video_cover {
    height: 235px;
    padding: 0 20px; }
  .video_on_photo .additions__video,
  .video_on_photo .additions__video_poster {
    left: 20px;
    right: 20px;
    overflow: hidden; }
  .video_on_photo .additions__icon_top {
    display: none; } }

.mulpiple_text .additions__item {
  padding-top: 4.625vw;
  padding-bottom: 4.625vw;
  max-width: 1650px;
  width: 100%;
  margin: 0 auto; }

.mulpiple_text .additions__info {
  max-width: calc(100% * 8/12 - (20px - 20px * 8/12));
  width: calc(100% * 8/12 - (20px - 20px * 8/12));
  margin: 0 auto;
  padding: 0 20px;
  text-align: center; }

.mulpiple_text .additions__title {
  margin-bottom: calc(26px + 14 * ((100vw - 375px)/ 1650));
  font-size: 34px;
  line-height: calc(26px + 14 * ((100vw - 375px)/ 1650));
  text-transform: uppercase; }

.mulpiple_text .additions__subtitle {
  display: block;
  margin-bottom: calc(16px + 4 * ((100vw - 375px)/ 1650));
  font-size: calc(14px + 2 * ((100vw - 375px)/ 1645));
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 2px; }

.mulpiple_text .additions__text {
  font-size: calc(14px + 4 * ((100vw - 375px)/ 1650));
  line-height: calc(26px + 6 * ((100vw - 375px)/ 1650));
  text-align: center; }
  .mulpiple_text .additions__text p {
    margin: 0; }
  .mulpiple_text .additions__text p + p {
    margin: 30px 0 0; }

.mulpiple_text .additions__text_2 {
  -webkit-column-count: 2;
  column-count: 2;
  grid-column-gap: 3.42vw;
  -webkit-column-gap: 3.42vw;
  column-gap: 3.42vw;
  text-align: left; }

@media (max-width: 1299px) {
  .mulpiple_text .additions__title {
    font-size: calc(24px + 10 * ((100vw - 420px)/ 860)); } }

@media (max-width: 1023px) {
  .mulpiple_text .additions__text_2 {
    webkit-column-count: 1;
    column-count: 1;
    grid-column-gap: 0;
    -webkit-column-gap: 0;
    column-gap: 0;
    text-align: center; }
  .mulpiple_text .additions__info {
    max-width: calc(100% * 1/1 - (10px - 10px * 1/1));
    width: calc(100% * 1/1 - (10px - 10px * 1/1));
    padding: 0 calc(99.9% / 12 - (10px - 10px / 12)); } }

@media (max-width: 480px) {
  .mulpiple_text .additions__subtitle {
    font-size: 14px; }
  .mulpiple_text .additions__title {
    font-size: 24px;
    line-height: 26px; }
  .mulpiple_text .additions__text {
    line-height: 28px;
    font-size: 14px; } }

.video_on_background .additions__item {
  padding-top: 4.625vw;
  padding-bottom: 4.625vw; }

.video_on_background .additions__video_cover {
  position: relative;
  display: block;
  height: 0;
  padding: 0 0 30.42%;
  overflow: hidden;
  z-index: 2; }

.video_on_background .additions__video {
  top: 0;
  right: calc(99.9% / 12 - (20px - 20px / 12));
  left: calc(99.9% / 12 - (20px - 20px / 12));
  position: absolute;
  width: auto;
  height: 100%; }

.video_on_background .video_addit {
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%; }

.video_on_background .additions__video_poster {
  top: 0;
  right: calc(99.9% / 12 - (20px - 20px / 12));
  left: calc(99.9% / 12 - (20px - 20px / 12));
  position: absolute;
  width: auto;
  height: 100%; }

.video_on_background .img_addit_poster {
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  -o-object-fit: cover;
  object-fit: cover;
  width: 99.9%;
  height: 100%; }

@media (max-width: 767px) {
  .video_on_background .additions__video_cover {
    height: 235px;
    padding: 0 20px; }
  .video_on_background .additions__video,
  .video_on_background .additions__video_poster {
    left: 20px;
    right: 20px;
    overflow: hidden; } }

.photo_text_left_right .additions__item {
  padding-top: 4.625vw;
  padding-bottom: 4.625vw; }

.photo_text_left_right .additions__flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }

.photo_text_left_right .additions__info_cover {
  width: 42%;
  padding: 0 calc(99.9% / 12 - (20px - 20px / 12)); }

.photo_text_left_right .additions__title {
  margin-bottom: calc(28px + 34 * ((100vw - 375px)/ 1650));
  font-size: calc(18px + 8 * ((100vw - 375px)/ 1650));
  text-transform: uppercase;
  line-height: calc(22px + 6 * ((100vw - 375px)/ 1650)); }

.photo_text_left_right .additions__text {
  font-size: calc(14px + 4 * ((100vw - 375px)/ 1650));
  line-height: calc(28px + 6 * ((100vw - 375px)/ 1650)); }
  .photo_text_left_right .additions__text p {
    margin: 0; }
  .photo_text_left_right .additions__text p + p {
    margin: 20px 0 0; }

.photo_text_left_right .additions__img_cover {
  width: 58%; }

.photo_text_left_right .additions__img_cover_left {
  order: -1; }

.photo_text_left_right .additions__img {
  max-height: 30.4vw;
  overflow: hidden;
  display: flex;
  align-items: center; }

.photo_text_left_right .img_addit {
  display: block;
  width: 100%;
  height: auto; }

@media (max-width: 1023px) {
  .photo_text_left_right .additions__flex {
    flex-direction: column;
    padding: 0 calc(99.9% / 12 - (20px - 20px / 12)); }
  .photo_text_left_right .additions__img_cover {
    order: -1;
    margin-bottom: 47px;
    width: 100%; }
  .photo_text_left_right .additions__img {
    max-height: 100%; }
  .photo_text_left_right .additions__info_cover {
    padding: 0 20px 17px;
    width: 100%; }
  .photo_text_left_right .additions__title {
    margin-bottom: 18px; } }

@media (max-width: 767px) {
  .photo_text_left_right .additions__flex {
    padding: 0 20px; } }

@media (max-width: 480px) {
  .photo_text_left_right .additions__title {
    line-height: 22px;
    font-size: 18px; }
  .photo_text_left_right .additions__text {
    line-height: 28px;
    font-size: 14px; } }

.six_gallery .additions__item {
  padding-top: 4.625vw;
  padding-bottom: 4.625vw; }

.six_gallery .additions__slider_cover {
  position: relative;
  display: block;
  height: 0;
  padding: 0 0 45.5%;
  overflow: hidden; }

.six_gallery .additions__slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-bottom: 0; }

.six_gallery .slick-slide {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 45.8vw; }

.six_gallery .six_gallery_slide {
  width: 50%; }
  .six_gallery .six_gallery_slide_1 {
    height: 50%; }
  .six_gallery .six_gallery_slide_2 {
    height: 50%; }
  .six_gallery .six_gallery_slide_3 {
    height: 100%; }
  .six_gallery .six_gallery_slide_4 {
    height: 100%; }
  .six_gallery .six_gallery_slide_5 {
    height: 50%; }
  .six_gallery .six_gallery_slide_6 {
    height: 50%; }

.six_gallery .additions__slide {
  width: 100%;
  height: 100%; }

.six_gallery .additions__link {
  display: block;
  width: 100%;
  height: 100%; }

.six_gallery .additions__img {
  width: 100%;
  height: 100%; }

.six_gallery .img_addit {
  display: block;
  margin: 0;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%; }

.six_gallery .slick-dots {
  top: 0;
  bottom: 0;
  right: 20px;
  width: auto;
  left: auto;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .six_gallery .slick-dots li {
    pointer-events: none; }
    .six_gallery .slick-dots li button {
      width: 20px;
      height: 20px;
      position: relative; }
      .six_gallery .slick-dots li button:before, .six_gallery .slick-dots li button:after {
        content: '';
        border: 1px solid #fff;
        border-radius: 10px;
        transition: width .3s,height .3s;
        width: 4px;
        height: 4px;
        position: absolute;
        top: 50%;
        left: 50%;
        background: transparent;
        transform: translate(-50%, -50%); }
      .six_gallery .slick-dots li button:hover::before, .six_gallery .slick-dots li button:focus::before, .six_gallery .slick-dots li button:active::before {
        width: 10px;
        height: 10px; }
    .six_gallery .slick-dots li.slick-active button::before {
      width: 10px;
      height: 10px; }

@media (max-width: 768px) {
  .six_gallery .additions__slider_cover {
    height: 50vh;
    padding: 0; }
  .six_gallery .slick-slide {
    height: 50vh; }
    .six_gallery .slick-slide > div {
      width: 100%;
      height: 100%; }
  .six_gallery .six_gallery_slide {
    width: 100%;
    height: 100%; }
  .six_gallery .slick-dots {
    flex-direction: row;
    bottom: 20px;
    left: 0;
    top: auto;
    width: 100%; }
    .six_gallery .slick-dots li {
      pointer-events: all; } }

.horizontal_gallery .additions__slider_cover {
  height: 90vh;
  overflow: hidden; }

.horizontal_gallery .additions__slider {
  width: 80vw; }
  .horizontal_gallery .additions__slider .slick-list {
    overflow: visible; }
  .horizontal_gallery .additions__slider.js_center {
    margin: 0 auto; }
  .horizontal_gallery .additions__slider.js_right {
    margin: 0 auto 0 0; }
  .horizontal_gallery .additions__slider.js_left {
    margin: 0 0 0 auto; }

.horizontal_gallery .additions__slide {
  position: relative;
  height: 90vh;
  cursor: pointer; }
  .horizontal_gallery .additions__slide:after {
    content: '';
    position: absolute;
    right: 0;
    height: 100%;
    top: 0;
    left: 0;
    opacity: .4;
    background-color: #161a11;
    transition: .3s;
    pointer-events: none; }
  .horizontal_gallery .additions__slide .additions__img {
    pointer-events: none; }
  .horizontal_gallery .additions__slide.slick-current .additions__img, .horizontal_gallery .additions__slide.slick-active .additions__img {
    pointer-events: auto; }
  .horizontal_gallery .additions__slide.slick-current:after, .horizontal_gallery .additions__slide.slick-active:after {
    opacity: 0; }
  .horizontal_gallery .additions__slide:hover:after, .horizontal_gallery .additions__slide:focus:after, .horizontal_gallery .additions__slide:active:after {
    opacity: 0; }

.horizontal_gallery .additions__img {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden; }

.horizontal_gallery .img_addit {
  display: block;
  margin: 0;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%; }

@media (max-width: 1199px) {
  .horizontal_gallery .additions__slide {
    padding: 0 5px; }
    .horizontal_gallery .additions__slide:after {
      right: 5px;
      left: 5px; }
  .horizontal_gallery .additions__slider.js_center {
    margin: 0 auto; }
  .horizontal_gallery .additions__slider.js_right {
    margin: 0 auto 0 0;
    margin-left: -5px; }
  .horizontal_gallery .additions__slider.js_left {
    margin: 0 0 0 auto;
    margin-right: -5px; } }

@media (max-width: 1023px) {
  .horizontal_gallery .additions__slider_cover {
    height: 50vh; }
  .horizontal_gallery .additions__slide {
    height: 50vh; } }

.tth_text {
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center; }
  .tth_text .additions__item {
    padding-top: 4.625vw;
    padding-bottom: 4.625vw;
    width: 100%; }
  .tth_text .additions__info_cover {
    margin: 0 auto;
    padding: 0 calc(99.9% / 12 - (20px - 20px / 12));
    position: relative;
    z-index: 5; }
  .tth_text .additions__text {
    font-size: 2.25rem;
    line-height: 3.15rem;
    margin-bottom: 165px;
    width: 50%;
    color: #f8f7f4; }
    .tth_text .additions__text p {
      margin: 0; }
    .tth_text .additions__text p + p {
      margin: 30px 0 0; }
  .tth_text .additions__flex {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px; }
  .tth_text .additions__flex_item {
    padding: 0 15px;
    width: 25%; }
  .tth_text .additions__chars {
    padding: 10px 0 0 0;
    color: #f8f7f4;
    height: 100%;
    display: flex;
    flex-direction: column; }
    .tth_text .additions__chars.js_opacity {
      opacity: 0; }
  .tth_text .additions__flex_item + .additions__flex_item .additions__chars {
    padding-left: 30px;
    border-left: 1px solid rgba(248, 247, 244, 0.2); }
  .tth_text .additions__chars_value {
    font-size: 5rem;
    line-height: 5rem;
    margin-top: auto; }
  .tth_text .additions__chars_title {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 16px; }
  .tth_text .additions__chars_name {
    font-weight: 600;
    color: #f8f7f4; }
  .tth_text .additions__chars_code {
    color: rgba(249, 249, 249, 0.5);
    white-space: nowrap; }
  .tth_text .additions__img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.3;
    filter: blur(10px); }

@media (max-width: 1299px) {
  .tth_text .additions__flex_item {
    width: 33.3%; } }

@media (max-width: 1199px) {
  .tth_text .additions__text {
    width: 100%; } }

@media (max-width: 1023px) {
  .tth_text {
    min-height: 50vh; }
    .tth_text .additions__text {
      margin-bottom: 60px;
      font-size: 1.375rem;
      line-height: 2rem; }
    .tth_text .additions__chars_value {
      font-size: 3.625rem;
      line-height: 3.625rem; } }

@media (max-width: 767px) {
  .tth_text .additions__flex_item {
    width: 100%; }
  .tth_text .additions__chars {
    border-left: 1px solid rgba(248, 247, 244, 0.2);
    padding-left: 40px;
    padding-bottom: 65px; }
  .tth_text .additions__item {
    padding-top: 60px;
    padding-bottom: 60px; } }

.colors .additions__item {
  padding-top: 4.625vw;
  padding-bottom: 4.625vw;
  background: radial-gradient(at 70%, at 54%, #9fa2a9, #212325);
  background: radial-gradient(at 70% 54%, #9fa2a9, #212325);
  background-size: 100% 100%; }

.colors .additions__container {
  padding: 0 15px;
  max-width: calc(100% - 50px);
  margin: 0 auto; }

.colors .additions__info {
  width: 58.33%; }

.colors .additions__subtitle {
  margin-bottom: 32px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.875rem; }

.colors .additions__title {
  margin-bottom: 20px;
  font-size: 2.25rem;
  line-height: 3.125rem;
  letter-spacing: .4px; }

.colors .additions__block {
  margin: 50px 0 0; }

.colors .additions__flex {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px; }

.colors .additions__models_nav__cover {
  padding: 0 15px;
  width: 33.33%; }

.colors .additions__models_nav__ul {
  display: flex;
  flex-direction: column; }

.colors .additions__models_nav__li {
  margin-bottom: 12px; }

.colors .additions__models_nav__link {
  padding: 0;
  opacity: .5;
  font-family: "optimaregular", sans-serif;
  color: #f7f8f4;
  cursor: pointer;
  border: none;
  background: transparent;
  transition: all 0.3s ease;
  font-size: 24px;
  font-weight: normal;
  line-height: 40px; }
  .colors .additions__models_nav__link.js_active {
    opacity: 1; }

.colors .additions__models_img__cover {
  padding: 0 15px;
  width: 66.66%; }

.colors .additions__models_img {
  top: 50%;
  transform: translateY(-50%);
  background-image: none;
  position: relative;
  height: 0;
  padding-top: 50%; }

.colors .additions__img {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.6s ease;
  visibility: hidden; }
  .colors .additions__img.js_active {
    opacity: 1;
    visibility: visible; }

.colors .img_addit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.6s ease; }

@media (max-width: 1199px) {
  .colors .additions__info {
    width: 100%; }
  .colors .additions__subtitle {
    margin-bottom: 15px;
    font-weight: bold; }
  .colors .additions__title {
    margin-bottom: 10px;
    font-size: 1.75rem;
    line-height: 2rem; }
  .colors .additions__block {
    margin: 0; }
  .colors .additions__models_nav__cover {
    width: 100%; }
  .colors .additions__models_nav {
    padding: 25px 0 35px 0;
    margin: 0 -15px; }
  .colors .additions__models_nav__ul {
    flex-direction: row; }
  .colors .additions__models_nav__li {
    margin-bottom: 0; }
  .colors .additions__models_nav__link {
    padding: 0 15px;
    font-size: 16px;
    font-weight: bold;
    line-height: 30px;
    white-space: nowrap; }
  .colors .additions__models_img__cover {
    width: 100%; }
  .colors .additions__models_img {
    top: 0;
    transform: translateY(0); } }

@media (max-width: 1023px) {
  .colors .additions__container {
    max-width: calc(100% - 12px); } }

.fullscreen_photo .additions__item {
  padding-top: 4.625vw;
  padding-bottom: 4.625vw; }

.fullscreen_photo .additions__container {
  padding: 0 15px;
  max-width: calc(100% - 50px);
  margin: 0 auto; }

.fullscreen_photo .additions__block {
  padding-bottom: 45%;
  position: relative; }

.fullscreen_photo .additions__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: 100% 100%; }

@media (max-width: 1199px) {
  .fullscreen_photo .additions__img {
    background-attachment: inherit; }
  .fullscreen_photo .additions__block {
    padding-bottom: 60%; }
  .fullscreen_photo .img_addit {
    display: block;
    width: 100%;
    height: auto; } }

@media (max-width: 1023px) {
  .fullscreen_photo .additions__container {
    max-width: calc(100% - 12px); } }

@media (max-width: 767px) {
  .fullscreen_photo .additions__block {
    padding-bottom: 65%; }
  .fullscreen_photo .additions__container {
    max-width: 100%;
    padding: 0; } }

.photo_text_block .additions__item {
  padding-top: 80px; }

.photo_text_block .additions__item_light {
  background: #f8f7f4;
  color: #161a21; }
  .photo_text_block .additions__item_light .additions__svg {
    background: #fff; }
    .photo_text_block .additions__item_light .additions__svg svg {
      fill: #161a21; }

.photo_text_block .additions__item_dark {
  color: #f8f7f4;
  background: #161a21; }
  .photo_text_block .additions__item_dark .additions__svg {
    background: #080B11; }
    .photo_text_block .additions__item_dark .additions__svg svg {
      fill: #f8f7f4; }

.photo_text_block .additions__container {
  padding: 0 15px;
  max-width: calc(100% - 50px);
  margin: 0 auto; }

.photo_text_block .additions__info {
  width: 50%; }

.photo_text_block .additions__subtitle {
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 1.86rem;
  font-weight: 600; }

.photo_text_block .additions__title {
  margin-bottom: 120px;
  font-size: 3.625rem;
  line-height: 3.625rem; }

.photo_text_block .additions__flex {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px; }

.photo_text_block .additions__text_cover {
  padding: 0 15px;
  width: 33.33%; }
  .photo_text_block .additions__text_cover.end {
    align-self: flex-end; }

.photo_text_block .additions__img_cover {
  padding: 0 15px;
  width: 66.67%; }

.photo_text_block .additions__img {
  height: 75vh;
  min-height: 520px;
  margin-bottom: 4px;
  position: relative;
  overflow: hidden; }

.photo_text_block .img_addit {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover; }

.photo_text_block .additions__text_title {
  font-size: 16px;
  line-height: 46px;
  font-weight: 600;
  margin-bottom: 16px; }

.photo_text_block .additions__text_text {
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 22px; }
  .photo_text_block .additions__text_text p {
    margin: 0; }
  .photo_text_block .additions__text_text p + p {
    margin: 15px 0 0; }

.photo_text_block .additions__author {
  display: flex;
  margin: 0 -15px; }

.photo_text_block .additions__author_img__cover {
  padding: 0 15px;
  flex: 0 0 auto; }

.photo_text_block .additions__author_img {
  width: 64px;
  height: 84px;
  position: relative;
  overflow: hidden; }

.photo_text_block .img_addit_author {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; }

.photo_text_block .additions__author_info {
  padding: 0 15px; }

.photo_text_block .additions__author_name {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px; }

.photo_text_block .additions__author_post {
  font-size: 16px;
  font-weight: 300; }

.photo_text_block .additions__blockquote {
  margin-bottom: 40px;
  font-size: 1.5rem;
  line-height: 2.5rem;
  position: relative; }

.photo_text_block .additions__svg {
  position: absolute;
  top: -60px;
  left: -30px;
  display: flex;
  width: 78px;
  height: 78px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: -1; }
  .photo_text_block .additions__svg svg {
    display: block;
    width: 14px;
    height: 14px; }

.photo_text_block .additions__sticky {
  position: sticky;
  top: 135px; }

.photo_text_block .additions__img--padding {
  padding-bottom: 80px; }

.photo_text_block .additions__flex--padding {
  position: relative;
  top: -80px; }

@media (max-width: 1199px) {
  .photo_text_block .additions__title {
    font-size: 2.25rem;
    line-height: 3.15rem;
    margin-bottom: 55px; }
  .photo_text_block .additions__subtitle {
    margin-bottom: 0; }
  .photo_text_block .additions__info {
    width: 100%;
    padding-top: 35px; }
  .photo_text_block .additions__text_cover {
    width: 100%;
    padding-bottom: 60px; }
    .photo_text_block .additions__text_cover.end {
      order: 1;
      padding: 30px 15px 35px 15px; }
  .photo_text_block .additions__img_cover {
    width: 100%; }
  .photo_text_block .additions__blockquote {
    margin-bottom: 25px;
    font-size: 1.375rem;
    line-height: 2rem; }
  .photo_text_block .additions__svg {
    width: 48px;
    height: 48px;
    position: static;
    margin-bottom: 15px; }
    .photo_text_block .additions__svg svg {
      width: 12px;
      height: 12px; }
  .photo_text_block .additions__img--padding {
    padding-bottom: 0; }
  .photo_text_block .additions__flex--padding {
    top: 0; }
  .photo_text_block .additions__item {
    padding-top: 4.625vw;
    padding-bottom: 4.625vw; }
  .photo_text_block .additions__text_title {
    line-height: 30px;
    margin-bottom: 7px; }
  .photo_text_block .additions__text_text {
    margin-bottom: 0; } }

@media (max-width: 1023px) {
  .photo_text_block .additions__container {
    max-width: calc(100% - 12px); } }

@media (max-width: 767px) {
  .photo_text_block .additions__container {
    max-width: 100%; }
  .photo_text_block .additions__title {
    margin-bottom: 35px; }
  .photo_text_block .additions__img_cover {
    width: 100%;
    padding: 0;
    margin-bottom: 4px; }
  .photo_text_block .additions__img {
    min-height: 100%; } }

.js_hide {
  display: none; }
