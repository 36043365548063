%transition {
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@mixin flex_center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin absolute {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

@mixin mask {
    position: relative;
    &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: block;
        pointer-events: none;
    }
}

@mixin psevdo {
    content: '';
    position: absolute;
    display: block;
}
