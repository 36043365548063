@media (max-width: 1023px) {
  .new_item {
    &__sidebar {
      width: 100%;
      margin-top: 3em;
    }
    &__cover {
      width: 100%;
    }
  }
  .share_block {
    max-width: 100%;
  }
  .news {
    &__top {
      padding: 0 0 40%;
    }
  }
  .form {
    &__flex {
      margin: 0 -7.5px;
      margin-bottom: 25px;
    }
    &__item {
      padding: 0 7.5px;
    }
  }
  .contacts {
    &__map {
      padding-bottom: 50%;
    }
  }
  .video {
    &__cover {
      width: 100%;
      max-width: 800px;
      margin: 0 auto;
    }
  }
  .contacts__team_item_cover {
    width: 50%;
  }
}


@media (max-width: 767px) {
  .news {
    &__cover {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    &__top {
        padding: 0 0 55%;
    }
  }
  .contacts {
    &__item {
      &_cover {
        width: 100%;
      }
      &_cover + &_cover {
        margin-top: 30px;
      }
      &_timework {
        margin-top: 20px;
      }
      &_title {
        margin: 0 0 20px;
      }
    }
    &__form {
      &_cover {
        margin-top: 3em;
        padding-top: 3em;
      }
    }
  }
  .form {
    &__title {
      font-size: 20px;
    }
    &__item {
      width: 100%;
    }
    &__item + &__item {
      margin-top: 15px;
    }
    &__block {
      padding-top: 10px;
      margin-bottom: 15px;
    }
  }
  .cat_unit {
    &__cover {
      width: 100%;
    }
    &__title {
      font-size: 18px;
      line-height: 1.3;
    }
  }
  .contacts__team_item_cover {
    width: 100%;
    max-width: 550px;
  }
}


@media (max-width: 575px) {
    .new_item {
      &__date {
        line-height: 28px;
        font-size: 14px;
      }
      &__title {
        line-height: 22px;
        font-size: 18px;
      }
    }
}


@media (max-height: 450px) {
  .mistake_cover {
    align-items: flex-start;
  }
}
