@import "~slick-carousel/slick/slick-theme.scss";
@import "~slick-carousel/slick/slick.scss";
// @import "~magnific-popup/dist/magnific-popup.css";
@import "~lightcase/src/css/lightcase.css";
@import "~fullpage.js/dist/jquery.fullpage.min.css";
// @import "~pagepiling.js/dist/jquery.pagepiling.css";
@import "~selectric/src/selectric.scss";


@import "components/normalize";
@import "components/fonts";
@import "components/variables";
@import "components/mixin";
@import "components/animation";
@import "components/default";
@import "components/components";
@import "components/modals";
@import "components/slider";
@import "components/form";
@import "components/cat";
@import "components/unit";
@import "components/header";
@import "components/footer";
@import "components/main_page";
@import "components/news";
@import "components/contacts";
@import "components/stock_car";

@import "components/media";

@import "components/additions";

.js_hide {
  display: none;
}
