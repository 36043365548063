.modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    z-index: 10;
    @include flex_center;
    &.js_active {
        opacity: 1;
        visibility: visible;
    }
    &__overlay {
        @include absolute;
        background: rgba($black, 0.5);
        cursor: pointer;
    }
    &__holder {
        position: relative;
        max-height: 90vh;
        overflow-y: auto;
        width: 100%;
        background: $white;
        box-shadow: 0 10px 30px rgba(203, 203, 203, 0.45);
        padding: 45px;
        &--standart {
            max-width: 490px;
        }
    }
    &__close {
        position: absolute;
        top: 10px;
        right: 10px;
        background: $tr;
        width: 40px;
        height: 40px;
        border: none;
        margin: 0;
        outline: none;
        box-shadow: none;
        padding: 5px;
        @include flex_center;
        &_span {
            display: block;
            width: 18px;
            height: 18px;
        }
        svg {
            fill: rgba($black, .5);
            @extend %transition;
        }
        &:hover,
        &:focus,
        &:active {
            svg {
                fill: $black;
            }
        }

    }
    &__header {
        font-family: $ff-regular;
        color: $bg_main;
        font-size: 24px;
        margin: 0 0 30px;
        text-align: center;
    }
    &__footer {
        padding-top: 35px;
    }
    .input__wrapper + .input__wrapper {
      margin-top: 20px;
    }
    .form__success {
      padding-top: 20px;
    }
}


@media (max-width: 767px) {
    .modal {
        &__holder {
            padding: 30px 15px;
        }
        &__header {
            font-size: 20px;
            margin: 0 0 15px;
        }
        &__close {
            top: 0;
            right: 0;
        }
        &__footer {
            padding-top: 15px;
            .block__auto {
                width: 100%;
                text-align: center;
                .butn {
                    width: 240px;
                }
            }
        }
    }
}
