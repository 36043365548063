@keyframes loader-animation {
   0% {
      transform: rotate(0);
   }
   100% {
       transform: rotate(360deg);
   }
}

@-webkit-keyframes loader-animation {
    0% {
       transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}
