*{
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    outline: none;
 }

 *:before, *:after {
   box-sizing: border-box;
   margin: 0;
   padding: 0;
}

 html {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 100%;
    margin: 0;
    padding: 0;
 }

 p iframe, p img {
    width: auto !important;
    margin: 0 auto;
    max-width: 100%;
    display: inline-block;
    height: auto !important;
 }

 p img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
 }

 p iframe {
    min-height: 400px;
    width: 100% !important;
    margin: 0 auto;
 }

 body {
    font-size: 12px;
    font-family: $ff-regular;
    background-color: $bg_main;
    color: $text_main;
    margin: 0;
    padding: 0;
    line-height: normal;
    margin: 0 auto;
    min-height: 100%;
    position: relative;
    width: 100%;
    overflow-x: hidden;
    &.overflow {
        overflow: hidden;
    }
    &.body__main {
      background-color: $bg_main;
      color: $text_main;
    }
    &.body__page {
      background-color: $text_main;
      color: $bg_main;
    }
 }

 h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    line-height: 1.2;
    font-weight: normal;
 }

 a {
    text-decoration: none;
 }

 a, label {
    -webkit-tap-highlight-color: transparent;
 }

 ul, ol {
    list-style: none;
    margin: 0;
    padding: 0;
 }

 input, button {
    border: none;
    outline: none;
    font-family: $ff-regular;
    cursor: pointer;
    &:hover,
    &:focus,
    &:active,
    &:focus-within {
        border: none;
        outline: none !important;
        -webkit-box-shadow: none;
                box-shadow: none;
    }
}

button {
    text-align: center;
}

 button::-moz-focus-inner {
  border: 0 !important;
 }

 input:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0 50px $white !important;
}

 textarea {
    resize: none;
    border: none;
    outline: none;
    &:hover,
    &:focus,
    &:active {
        border: none;
        outline: none;
        -webkit-box-shadow: none;
                box-shadow: none;
    }
 }

 video {
    display: block;
 }

 a:hover, a:focus, a:active, a:visited {
    text-decoration: none;
    outline: none;
 }

 .site {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    min-height: 100%;
    &.js_active {
        overflow: hidden;
    }
 }

 .overlay_main {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    bottom: 0;
    background: $black;
    opacity: 0;
    cursor: pointer;
    z-index: -1;
 }

 .main-wrap {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
       -moz-box-flex: 1;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
    position: relative;
    // margin-bottom: 300px;
    // padding-top: 113px;
 }

 .main-wrap__page {
   display: flex;
   flex-direction: column;
 }


 // up_button !!!!!
  #up_button {
     display: -webkit-box;
     display: -webkit-flex;
     display: -moz-box;
     display: -ms-flexbox;
     display: flex;
     width: 40px;
     height: 40px;
     -webkit-box-pack: center;
     -webkit-justify-content: center;
     -moz-box-pack: center;
     -ms-flex-pack: center;
     justify-content: center;
     -webkit-box-align: center;
     -webkit-align-items: center;
     -moz-box-align: center;
     -ms-flex-align: center;
     align-items: center;
     position: fixed;
     right: 30px;
     bottom: 30px;
     visibility: hidden;
     z-index: 7;
     border-radius: 4px;
     background: $white;
     // border: 2px solid $bordo_main;
     padding: 0;
     cursor: pointer;
     @extend %transition;
     svg {
         // fill: $bordo_main;
         width: 20px;
         height: 20px;
     }
     &.js_visible {
         visibility: visible;
     }
     &:hover,
     &:focus,
     &:active {
         // background: $bordo_main;
         svg {
             fill: $white;
         }
     }
  }

 .img_responsive {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    &.big {
        width: 100%;
    }
    &.not_margin {
        margin: 0;
    }
 }

 .site-svg {
     display: block;
     width: 100%;
     height: 100%;
 }

 // CONTAINER !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
.section_white {
    background: $white;
}

 .container-cover {
    width: 100%;
    max-width: 1655px;
    padding: 0 15px;
    margin: 0 auto;
 }

.container-relative {
 position: relative;
}

 .container-max {
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;
    position: relative;
 }

 .container-900 {
     width: 100%;
     max-width: 900px;
     padding: 0 15px;
     margin: 0 auto;
 }
 .container-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    &.nowrap {
        -webkit-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
                flex-wrap: nowrap;
    }
    &.between {
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
           -moz-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
    }
    &.start {
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
           -moz-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
    }
    &.column {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
    &.baseline {
        -webkit-box-align: baseline;
        -webkit-align-items: baseline;
           -moz-box-align: baseline;
            -ms-flex-align: baseline;
                align-items: baseline;
    }
    &.center {
        justify-content: center;
    }
    &.end {
        justify-content: flex-end;
    }
 }

 .flex-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
 }


.no_padding {
    padding: 0;
}

.block {
    &_20 {
        width: 20%;
    }
    &_25 {
        width: 25%;
    }
    &_33 {
        width: 33.3%;
    }
    &_50 {
        width: 50%;
    }
    &_30 {
      width: 30%;
    }
    &_70 {
      width: 70%;
    }
    &_100 {
      width: 100%;
    }
}

.margin_minus {
    &--10 {
        margin: 0 -10px;
        > .block {
            padding: 0 10px;
        }
    }
    &--15 {
        margin: 0 -15px;
        > .block {
            padding: 0 15px;
        }
    }
}

.block_holder {
  &.margin_minus--30 {
    margin-bottom: -30px;
    >.margin_minus--15 {
      >.block {
        padding-bottom: 30px;
      }
    }
  }
  &.margin_minus--20 {
    margin-bottom: -20px;
    >.margin_minus--10 {
      >.block {
        padding-bottom: 20px;
      }
    }
  }
}

// TITLE
.title {
    line-height: 1.3;
    color: $text_main;
    font-family: $ff-regular;
    &--black {
      color: $bg_main;
    }
    &_center {
        text-align: center;
    }
    &__page {
      position: relative;
      z-index: 3;
      font-size: calc(26px + 34 * ((100vw - 375px)/ 1650));
      text-align: center;
      text-transform: uppercase;
      line-height: calc(26px + 28 * ((100vw - 375px)/ 1650));
    }
    &__car {
      font-size: calc(18px + 34 * ((100vw - 375px)/ 1650));
      line-height: calc(20px + 34 * ((100vw - 375px)/ 1650));
      margin-bottom: 20px;
    }
}

@media (max-width: 575px) {
    .title {
      &__page {
          line-height: 26px;
          font-size: 26px;
      }
      &__car {
        font-size: 24px;
        line-height: 24px;
      }
    }
}


// button ------------------
.link_block {
    display: block;
}

.my_btn {
    display: inline-block;
    line-height: 1;
    font-size: 10px;
    font-family: $ff-regular;
    font-weight: bold;
    min-width: 140px;
    text-align: center;
    white-space: nowrap;
    border-radius: 3px;
    padding: 11px 20px 12px 20px;
    text-transform: uppercase;
    @extend %transition;
    &.with_arrow {
        min-width: 200px;
        display: inline-flex;
        justify-content: space-around;
        justify-content: space-evenly;
        align-items: center;
        svg {
            display: block;
            width: 18px;
            height: 15px;
            margin-left: 7px;

        }
        &--left {
            svg {
                transform: rotate(180deg);
            }
        }
    }
}

.btn_form {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 40px;
    padding: 0 20px;
    font-size: 10px;
    min-width: 160px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    color: $text_main;
    letter-spacing: .1em;
    cursor: pointer;
    border: none;
    border-radius: 3px;
    background: $grey_87;
    border: 1px solid $grey_87;
    transition: background .225s cubic-bezier(.39,.575,.565,1);
    box-sizing: border-box;
    &:hover,
    &:focus,
    &:active {
      background: $grey_a1;
      border: 1px solid $grey_a1;
    }
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 20px;
}


.btn_block {
    display: block;
    text-align: center;
    width: 100%;
}
