.stock_car__nav {
  padding: 20px 40px 0;
  overflow: hidden;
  visibility: hidden;
  &.slick-initialized {
    visibility: visible;
  }
  .slick-list {
    margin: 0 -10px;
  }
  .stock_car__nav_img {
    padding: 0 10px;
  }
}


.stock_car {
  &__info {
    color: $bg_main;
  }
  &__price {
    font-size: calc(18px + 13 * ((100vw - 375px)/ 1650));
    line-height: calc(20px + 13 * ((100vw - 375px)/ 1650));
    margin-bottom: 20px;
  }
  &__btn {
    margin: 30px 0;
  }
  &__bottom_chars {
    .stock_car__chars_name {
      flex: 0 0 200px;
    }
  }
  &__chars {
    display: flex;
    margin: 0 -5px;
    font-size: 16px;
    line-height: 1.4;
    &_cover + &_cover{
        margin: 5px 0 0;
    }
    &_name {
      font-weight: 600;
      padding: 0 5px;
      flex: 0 0 120px;
    }
    &_value {
      padding: 0 5px;
    }
  }
  &__prim {
    font-size: 12px;
    line-height: 1.4;
    margin-top: 40px;
    padding-top: 2vw;
  }
}

.stock_car__text {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid $bg_main;
    font-size: 16px;
    line-height: 1.4;
    font-family: $ff-regular;
    p {
      margin: 0;
    }
    h1,h2,h3,h4,h5,h6 {
        line-height: 1.2;
        font-family: $ff-regular;
        color: $bg_main;
        margin: 10px 0 5px;
    }
    h2 {
        font-size: 20px;
    }
    h3 {
        font-size: 18px;
    }
    h4, h5, h6  {
        font-size: 18px;
    }
    p + p {
      margin: 5px 0 0;
    }
    p + ul,
    p + ol {
      margin: 5px 0 0;
    }
    ul + p,
    ol + p {
      margin: 5px 0 0;

    }
    p {
      margin: 0;
        a {
            display: inline;
            color: $bg_main;
            text-decoration: underline;
        }
        img, iframe {
            display: block;
            max-width: 100%;
            height: auto !important;
            margin: 0 auto;
        }
    }
    ul,ol {
        list-style-position: inside;
        li {
            a {
                display: inline;
                color: $bg_main;
                text-decoration: underline;
            }
            img, iframe {
                display: block;
                max-width: 100%;
                height: auto !important;
                margin: 0 auto;
            }
        }
        li+ li {
            margin-top: 5px;
        }
    }
    ul {
        list-style-type: disc;
    }
    ol {
        list-style-type: decimal;
    }
}


@media (max-width: 1023px) {
  .stock_car {
    &__cover {
      width: 100%;
    }
    &__photo {
      max-width: 800px;
      margin: 0 auto;
    }
    &__cover + &__cover {
      margin-top: 30px;
    }
  }
}

@media(max-width: 575px) {
  .stock_car {
    &__price {
      font-size: 22px;
      line-height: 22px;
    }
    &__prim {
      margin-top: 20px;
    }
  }
}
