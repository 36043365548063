// ----------------breadcrumbs----------------------------------
.breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -7.5px;
    &_cover {
        margin-bottom: 20px;
    }
    .crumb {
        display: block;
        color: $bg_main;
        font-size: 10px;
        padding: 5px 7.5px;
        &.js-lastcrumb {
            pointer-events: none;
        }
    }
    .crumb-li {
        position: relative;
    }
    .crumb-li + .crumb-li {
        &::after {
            content: '';
            position: absolute;
            top: 6px;
            left: 0;
            bottom: 6px;
            width: 1px;
            background: $bg_main;
        }
    }
}
// --------------------breadcrumbs------------------------------
// -----------pagination---------------------------------
.pagination_cover {
    .pagination {
        margin: 0 -2.5px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 30px;
        li {
            padding: 0 2.5px;
            margin-bottom: 5px;
            &:first-child,
            &:last-child {
                span, a {
                    font-size: 20px;
                }
            }
            span, a {
                font-size: 12px;
                color: $bg_main;
                display: block;
                line-height: 1;
                border: 1px solid $bg_main;
                @include flex-center;
                width: 25px;
                height: 25px;
                background: $tr;
                @extend %transition;
            }
            &.active {
                span, a {
                    background: $bg_main;
                    color: $text_main;
                }
            }
            &.disabled {
                span, a {
                    opacity: 0.5;
                    background: $tr !important;
                    pointer-events: none;
                }
            }
            &:hover,
            &:focus,
            &:active {
                span, a {
                    background: $bg_main;
                    color: $text_main;
                }
            }
        }
    }
}
// -------------pagination------------------------------------
// -------------video-----------------------------------------
.video {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    video,
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
}
//--------------video---------------------------------------
// ------------------share_block---------------------------
.share_block {
  max-width: 100px;
  margin: 0 auto;
  &__cover {
    height: 100%;
  }
  &__title {
    font-size: 12px;
    margin-bottom: 10px;
  }
  &__block {
    display: flex;
    align-items: center;
    margin: 0 -10px;
    justify-content: flex-start;
  }
  &__item {
    padding: 0 10px;
    width: 40px;
    height: 20px;
  }
  &__link {
      display: block;
      height: 100%;
      width: 100%;
      svg {
        fill: $bg_main;
      }
      &:hover,
      &:focus,
      &:active {
        svg {
          fill: rgba($bg_main, .8);
        }
      }
  }
}
// -----------------share_block-----------------------------
// -------------------mistake page--------------------------
  .mistake_cover {
    background: $grey_53;
    position: fixed;
    z-index: 2;
    left: 0;
    width: 100%;
    top: 0;
    height: 100%;
    color: $text_main;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow-y: auto;
    padding: 100px 0;
    .mistake_title {
      margin-bottom: calc(24px + 4 * ((100vw - 375px)/ 1650));
      font-size: calc(23px + 34 * ((100vw - 375px)/ 1650));
      line-height: calc(26px + 24 * ((100vw - 375px)/ 1650));
      text-transform: uppercase;
    }
    .mistake_subtitle {
      font-size: calc(14px + 4 * ((100vw - 375px)/ 1650));
      line-height: calc(28px + 6 * ((100vw - 375px)/ 1650));
    }
    .mistake_block {
      max-width: calc(100% * 8/12 - (20px - 20px * 8/12));
      padding: 0  10px;
      margin: 0 auto;
    }
    .mistake_link {
        margin-top: 50px;
    }
}


// ---------------------mistake page------------------------
